<template>
   <div id="assignment">
        <div class="right">
            <div class="header">
                <h4>Assignments</h4>
                <div class="list-btn">
                    <div>
                        <!-- <div v-if="activeButtonIndex==0" class="dot"></div> -->
                        <div @click="changeButtonFilter(BUTTONS_FILTER.INCOMPLETE)" :class="{ 'btn': true, 'active': activeButtonIndex == BUTTONS_FILTER.INCOMPLETE }">Incomplete</div>
                    </div>
                    <div>
                        <!-- <div v-if="activeButtonIndex==1" class="dot"></div> -->
                        <div @click="changeButtonFilter(BUTTONS_FILTER.COMPLETED)" :class="{ 'btn': true, 'active': activeButtonIndex == BUTTONS_FILTER.COMPLETED}">Completed</div>
                    </div>
                    <div>
                        <!-- <div v-if="activeButtonIndex==2" class="dot"></div> -->
                        <div @click="changeButtonFilter(BUTTONS_FILTER.ALL)" :class="{ 'btn': true, 'active': activeButtonIndex == BUTTONS_FILTER.ALL }">All</div>
                    </div>
                </div>

                <div class="search">
                    <div class="form">
                        <img class="icon-search" src="/themes/web/assets/images/icon/icon-search.svg" alt="">
                        <input v-model="keySearch" @input="searchAssignments" class="form-search" type="text" name="" :placeholder="$t('Search an assignment')" id="q"/>
                    </div>
                </div>
            </div>
            <div class="block-filter">
                <div style="display: flex; justify-content: space-between; gap: 16px; flex-wrap: wrap;">
                    <div class="filter" style="position: relative;">
                        <div class="label">Course</div>
                        <div :class="course_id_filter!=0 && course_id_filter!=-1 ? 'input-filter active': 'input-filter'" @click="showSlection(1)">
                            <p :class="course_id_filter!=0&& course_id_filter!=-1  ? 'p-active' : ''">{{ course_id_filter == 0 || course_id_filter == -1 ? 'Select a Course' : course_name_filter }}</p>
                            <svg v-if="course_id_filter!=0&& course_id_filter!=-1" xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                                <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="#FC7A1B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                                <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="#CFCFCF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="list-choice" v-if="selection_1" style="position: absolute; width: 100%; border-radius: 8px; overflow: hidden">
                            <div :class="course.class_id == class_id_filter ? 'item-choice active' : 'item-choice'" v-for="course in courses" :key="course.course_id + course.class_id" @click="filterCourse(course)">
                                <p>
                                    {{ course.course_name }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="filter" style="position: relative;">
                        <div class="label">Module</div>
                        <div :class="{ 'input-filter': true, 'active': module_id_filter!=0, 'disable': course_id_filter==-1 || course_id_filter==0}" @click="showSlection(2)">
                            <p :class="module_id_filter!=0 ? 'p-active' : ''">{{ module_id_filter == 0 ? 'Select a Module' : module_name_filter }}</p>
                            <svg v-if="module_id_filter!=0" xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                                <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="#FC7A1B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                                <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="#CFCFCF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="list-choice" v-if="selection_2" style="position: absolute; width: 100%;">
                            <div v-if="course_id_filter!=0">
                                <div :class="mld.id == module_id_filter ? 'item-choice active' : 'item-choice'" v-for="mld in modules" :key="mld.id" @click="filterModule(mld)">
                                    <p>
                                        {{ mld.module_name }}
                                    </p>
                                </div>
                            </div>
                            <!-- <div v-else class="item-choice">
                                You must select a Course first.
                            </div> -->
                        </div>
                    </div>

                    <div class="filter" style="position: relative;">
                        <div class="label">Lesson</div>
                        <div :class="{ 'input-filter': true, 'active': lesson_id_filter!=0, 'disable': module_id_filter==0}" @click="showSlection(3)">
                            <p :class="lesson_id_filter!=0 ? 'p-active' : ''">{{ lesson_id_filter == 0 ? 'Select a Lesson' : lesson_name_filter }}</p>
                            <svg v-if="lesson_id_filter!=0" xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                                <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="#FC7A1B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                                <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="#CFCFCF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="list-choice" v-if="selection_3" style="position: absolute; width: 100%;">
                            <div v-if="module_id_filter!=0">
                                <div :class="ls.id == lesson_id_filter ? 'item-choice active' : 'item-choice'" v-for="ls in lessons" :key="ls.id" @click="filterLesson(ls)">
                                    <p>
                                        {{ ls.lesson_name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="filter" style="position: relative;">
                        <div class="label">Assignment Type</div>
                        <div :class="{ 'input-filter': true}" @click="showSlection(4)">
                            <p>{{ type_assignment == 0 ? 'Assignment' : 'Flex assigment' }}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                                <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="#CFCFCF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="list-choice" v-if="selection_4" style="position: absolute; width: 100%;">
                            <div>
                                <div class="item-choice" v-for="t in listTypeAsm" :key="t.id" @click="filterTypeAssignment(t)">
                                    <p>
                                        {{ t.name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="filter">
                        <div class="label">Status</div>
                        <div :class="{ 'input-filter': true, 'active': status!=''}"  @click="showSlection(3)">
                            <p :class="status!='' ? 'p-active' : ''">{{ status == "" ? 'Select a Status' : status }}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                                <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="#CFCFCF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="list-choice" v-if="selection_3">
                            <div v-if="course_id_filter!=0">
                                <div class="item-choice" v-for="(value, key) in EAssignmentStatus" :key="key" v-if="value != '' " @click="filterStatus(key)">
                                    {{ value }}
                                </div>
                            </div>
                            <div v-else class="item-choice">
                                You must select a Course first.
                            </div>
                        </div>
                    </div> -->
                    </div>
                        <label class="control-asm">
                            <input type="radio" name="typeAsm" value="0" v-model="selected_asm">
                            <p>
                                Assignment
                            </p>
                        </label>
                        <label class="control-asm">
                            <input type="radio" name="typeAsm" value="1" v-model="selected_asm">
                            <p>
                                Flex Assignment
                            </p>
                        </label>
                    <div>

                </div>
                <p @click="clearFilter()" class="clear">Clear Filter</p>
            </div>

            <!-- <div v-if="course_id_filter==0" class="no-filter">
                You must select a Course first to display all of its assignments
            </div> -->
            <div  class="content">
                <div class="header">
                    <p v-if="BUTTONS_FILTER.COMPLETED == activeButtonIndex">{{ assignments.length }} assignments you have completed</p>
                    <p v-else-if="BUTTONS_FILTER.ALL == activeButtonIndex">You have a total of {{ assignments.length }} assignments</p>
                    <p v-else>{{ assignments.length }} assignments you need to complete</p>
                </div>
                <div v-if="assignments.length==0" class="no-data">
                    No assignments available yet. Check back soon!
                </div>
                <div v-else class="list-asm">
                    <div :class="asm.status!=EAssignmentStatus.OVERDUE ? (asm.status==EAssignmentStatus.NOT_STARTED ? 'item-asm notstarted' : 'item-asm') : 'item-asm overdue'" v-for="(asm, index) in assignments" :key="index">
                        <div style="display: flex; align-items: center;">
                            <img v-if="asm.status==EAssignmentStatus.ON_GOING" src="/themes/web/assets/images/icon/asm/new.svg" alt="">
                            <img v-if="asm.status==EAssignmentStatus.INPROGRESS" src="/themes/web/assets/images/icon/asm/inprogress.svg" alt="">
                            <img v-if="asm.status==EAssignmentStatus.SUBMITED" src="/themes/web/assets/images/icon/asm/submitted.svg" alt="">
                            <img v-if="asm.status==EAssignmentStatus.COMPLETED" src="/themes/web/assets/images/icon/asm/graded.svg" alt="">
                            <img v-if="asm.status==EAssignmentStatus.NEED_RESUBMIT" src="/themes/web/assets/images/icon/asm/return.svg" alt="">
                            <img v-if="asm.status==EAssignmentStatus.OVERDUE" src="/themes/web/assets/images/icon/asm/overdue.svg" alt="">
                            <img v-if="asm.status==EAssignmentStatus.NOT_STARTED" src="/themes/web/assets/images/icon/asm/not-started.svg" alt="">

                            <div style="margin-left: 10px;">
                                <div v-if="asm.is_flex == 1" class="info">
                                    <p class="type-asm">Flex Assignment</p>
                                    <div class="dot-1"></div>
                                    <p class="course-title">{{ asm.course_name }}</p>
                                </div>
                                <div v-else class="info">
                                    <p class="course-title">{{ asm.course_name }}</p>
                                    <div class="dot-1"></div>
                                    <p class="path">{{ asm.path }}</p>
                                </div>

                                <h4 class="title" v-if="asm.is_flex==1">{{ asm.asm_name }}</h4>
                                <h4 class="title" v-else>{{ asm.content_name }}</h4>

                                <div v-if="asm.is_flex == 1 && (asm.status == EAssignmentStatus.SUBMITED || asm.status == EAssignmentStatus.COMPLETED)" class="date">
                                    Submisstion time: {{ (new Date()).customDate(asm.submission_time, user_info.timezone) }}
                                </div>
                                <div v-else-if="asm.is_flex == 1" class="date">
                                    Start: {{ (new Date()).customDate(asm.start_time, user_info.timezone) }} - End: {{ (new Date()).customDate(asm.end_time, user_info.timezone) }}
                                </div>
                            </div>
                        </div>
                        <div v-if="asm.status==EAssignmentStatus.ON_GOING" style="display: flex; align-items: center;">
                            <p class="p-status new">To do</p>
                            <img style="cursor: pointer; width: 90px" @click="openAssignment(asm)" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                        <div v-if="asm.status==EAssignmentStatus.INPROGRESS" style="display: flex; align-items: center;">
                            <p class="p-status inprogress">In Progress</p>
                            <img style="cursor: pointer; width: 90px" @click="openAssignment(asm)" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                        <div v-if="asm.status==EAssignmentStatus.SUBMITED" style="display: flex; align-items: center;">
                            <p class="p-status submitted">Submitted</p>
                            <img style="cursor: pointer; width: 90px" @click="openAssignment(asm)" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                        <div v-if="asm.status==EAssignmentStatus.COMPLETED" style="display: flex; align-items: center;">
                            <p class="p-status graded">Completed: {{ truncateToTwoDecimals(asm.core) }} / {{ asm.total }}</p>
                            <img style="cursor: pointer; width: 90px" @click="openAssignment(asm)" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                        <div v-if="asm.status==EAssignmentStatus.NEED_RESUBMIT" style="display: flex; align-items: center;">
                            <p class="p-status return">Need to Resubmit</p>
                            <img style="cursor: pointer; width: 90px" @click="openAssignment(asm)" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                        <div v-if="asm.status==EAssignmentStatus.OVERDUE" style="display: flex; align-items: center;">
                            <p class="p-status overdue">Overdue</p>
                            <img style="cursor: pointer; width: 90px" @click="openAssignment(asm)" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                        <div v-if="asm.status==EAssignmentStatus.NOT_STARTED" style="display: flex; align-items: center;">
                            <p class="p-status notstarted">Not Started</p>
                            <img class="img-notstarted" style="cursor: pointer; width: 90px" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                    </div>


                    <!-- <div class="item-asm">
                        <div style="display: flex; align-items: center;">
                            <p class="index"><span>1</span>/6</p>
                            <img src="/themes/web/assets/images/icon/asm/inprogress.svg" alt="">
                            <div style="margin-left: 10px;">
                                <div class="info">
                                    <p class="type-asm">Required</p>
                                    <div class="dot-1"></div>
                                    <p class="course-title">Course Title</p>
                                    <div class="dot-1"></div>
                                    <p class="path">Theme 1 - Unit 1 - Lesson 1</p>
                                </div>

                                <h4 class="title">This is a Very Long Assignment Title</h4>

                                <div class="date">
                                    Start: hh:mm dd/mm/yyyy - End: hh:mm dd/mm/yyyy
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <p class="p-status inprogress">In Progress</p>
                            <img style="cursor: pointer;" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                    </div>

                    <div class="item-asm">
                        <div style="display: flex; align-items: center;">
                            <p class="index"><span>1</span>/6</p>
                            <img src="/themes/web/assets/images/icon/asm/submitted.svg" alt="">
                            <div style="margin-left: 10px;">
                                <div class="info">
                                    <p class="type-asm">Required</p>
                                    <div class="dot-1"></div>
                                    <p class="course-title">Course Title</p>
                                    <div class="dot-1"></div>
                                    <p class="path">Theme 1 - Unit 1 - Lesson 1</p>
                                </div>

                                <h4 class="title">This is a Very Long Assignment Title</h4>

                                <div class="date">
                                    Start: hh:mm dd/mm/yyyy - End: hh:mm dd/mm/yyyy
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <p class="p-status submitted">Submitted</p>
                            <img style="cursor: pointer;" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                    </div>

                    <div class="item-asm">
                        <div style="display: flex; align-items: center;">
                            <p class="index"><span>1</span>/6</p>
                            <img src="/themes/web/assets/images/icon/asm/graded.svg" alt="">
                            <div style="margin-left: 10px;">
                                <div class="info">
                                    <p class="type-asm">Required</p>
                                    <div class="dot-1"></div>
                                    <p class="course-title">Course Title</p>
                                    <div class="dot-1"></div>
                                    <p class="path">Theme 1 - Unit 1 - Lesson 1</p>
                                </div>

                                <h4 class="title">This is a Very Long Assignment Title</h4>

                                <div class="date">
                                    Start: hh:mm dd/mm/yyyy - End: hh:mm dd/mm/yyyy
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <p class="p-status graded">Graded: A+</p>
                            <img style="cursor: pointer;" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                    </div>

                    <div class="item-asm">
                        <div style="display: flex; align-items: center;">
                            <p class="index"><span>1</span>/6</p>
                            <img src="/themes/web/assets/images/icon/asm/return.svg" alt="">
                            <div style="margin-left: 10px;">
                                <div class="info">
                                    <p class="type-asm">Required</p>
                                    <div class="dot-1"></div>
                                    <p class="course-title">Course Title</p>
                                    <div class="dot-1"></div>
                                    <p class="path">Theme 1 - Unit 1 - Lesson 1</p>
                                </div>

                                <h4 class="title">This is a Very Long Assignment Title</h4>

                                <div class="date">
                                    Start: hh:mm dd/mm/yyyy - End: hh:mm dd/mm/yyyy
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <p class="p-status return">Returned</p>
                            <img style="cursor: pointer;" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                    </div>

                    <div class="item-asm overdue">
                        <div style="display: flex; align-items: center;">
                            <p class="index"><span>1</span>/6</p>
                            <img src="/themes/web/assets/images/icon/asm/overdue.svg" alt="">
                            <div style="margin-left: 10px;">
                                <div class="info">
                                    <p class="type-asm">Required</p>
                                    <div class="dot-1"></div>
                                    <p class="course-title">Course Title</p>
                                    <div class="dot-1"></div>
                                    <p class="path">Theme 1 - Unit 1 - Lesson 1</p>
                                </div>

                                <h4 class="title">This is a Very Long Assignment Title</h4>

                                <div class="date">
                                    Start: hh:mm dd/mm/yyyy - End: hh:mm dd/mm/yyyy
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <p class="p-status overdue">Overdue</p>
                            <img style="cursor: pointer;" src="/themes/web/assets/images/icon/asm/btn-play.png" alt="">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import axios from "axios";
    import {getAssignments,  AssignmentEntity, EAssignmentStatus, BUTTONS_FILTER, StatusPriority} from "../../models/assignment";
    import {getAllCourse, getUnit1, getLesson} from "../../models/dashboard";
    import { formatDate, truncateToTwoDecimals } from '../../utils/utils'
    
    export default {
        name: "Assignment",
        title: 'Assignment',

        components:{},

        data() {
            return{
                listTypeAsm:[
                    {
                        id: 1,
                        name: 'Flex assignment'
                    },
                    {
                        id: 0,
                        name: 'Assignment'
                    },
                ],
                BUTTONS_FILTER,
                EAssignmentStatus,
                courses: [],
                rawCourses: [],
                status: EAssignmentStatus.UNKNOW,
                course_id_filter: 0,
                module_id_filter: 0,
                lesson_id_filter: 0,
                module_name_filter: "",
                lesson_name_filter: "",
                course_name_filter: "",
                rawAssignmnets: [],
                // themes: [],
                modules: [],
                lessons: [],
                assignments: [],
                activeButtonIndex: 1, 
                selection_1: false,
                selection_2: false,
                selection_3: false,
                selection_4: false,
                keySearch: "",
                class_id_filter: 0,
                selected_asm: 0,
                type_assignment: 0,
                filterAsm: [],
            }
        },

        mounted() {
            document.addEventListener("click", this.handleDocumentClick);
            if(this.$store.getters.isShowMenu){
                $('#assignment .right').css({'left' : '130px'});
                $('#subject >.right').css({'left' : '130px'});
            }else{
                $('#assignment .right').css({'left' : '50px'});
                $('#subject >.right').css({'left' : '50px'});
            }
        },

        beforeDestroy() {
            document.removeEventListener("click", this.handleDocumentClick);
        },

        computed: {
            user_info() {
                return this.$store.state.user_info;
            },
        },

        watch: {
            selected_asm(newVal, oldVal) {
                this.filterTypeAsm({isFlex: newVal})
                this.filterAssigmnets({buttonFilter: this.activeButtonIndex, searchText: this.keySearch, status: this.status, isFlex: newVal})
                const courseIdsInAssignments = new Set(this.filterAsm.map(asm => asm.course_id));
                const filteredCourses = this.rawCourses.filter(c => courseIdsInAssignments.has(c.course_id));
                this.courses = filteredCourses
                this.module_id_filter = 0;
                this.course_id_filter = -1;
                this.class_id_filter = -1;
                this.lesson_id_filter = 0;
            }
        },

        created() {
            this.fetch()
            this.$store.commit('setMenuActive', 'assignment')
        },

        methods: {
            formatDate,
            truncateToTwoDecimals,
            
            async fetch(){
                await this.getAllCourse(0)
                await this.getAllAssignments()
            },

            filterTypeAssignment(a){
                this.type_assignment = a.id
                this.selected_asm = a.id
            },

            openAssignment(asm){
                // if(asm.is_flex == 0){
                    this.$router.push({ path: '/lesson/'+asm.class_id+'/'+asm.course_id+'-'+asm.module_id+'-'+asm.lesson_id+'-'+asm.lesson_package_id+'-'+asm.course_item_id })
                // }else{
                //     this.$router.push({ name: 'flex_detail', params: { asm } });
                // }
            },

            searchAssignments(){
                this.filterAssigmnets({course_id: this.course_id_filter, buttonFilter: this.activeButtonIndex, searchText: this.keySearch, status: this.status, module_id: this.module_id_filter, lesson_id: this.lesson_id_filter, class_id: this.class_id_filter, isFlex: this.selected_asm })
            },

            changeTypeAsm(event) {
                console.log('Selected option:', event.target.value);
            },

            filterCourse(course){
                this.module_id_filter = 0;

                this.assignments = this.rawAssignmnets.slice();

                this.course_id_filter = course.course_id
                this.course_name_filter = course.course_name
                this.class_id_filter = course.class_id
                this.getUnit(this.course_id_filter)
                this.filterAssigmnets({course_id: this.course_id_filter, buttonFilter: this.activeButtonIndex, searchText: this.keySearch, status: this.status, class_id: course.class_id, isFlex: this.selected_asm})
            },

            filterModule(module){
                this.module_id_filter = module.id
                this.module_name_filter = module.module_name

                this.getLesson(this.course_id_filter, this.module_id_filter)
                this.filterAssigmnets({course_id: this.course_id_filter, buttonFilter: this.activeButtonIndex, searchText: this.keySearch, status: this.status, module_id: this.module_id_filter, class_id: this.class_id_filter, isFlex: this.selected_asm })
            },

            filterLesson(lesson){
                this.lesson_id_filter = lesson.id
                this.lesson_name_filter = lesson.lesson_name

                this.filterAssigmnets({course_id: this.course_id_filter, buttonFilter: this.activeButtonIndex, searchText: this.keySearch, status: this.status, module_id: this.module_id_filter, lesson_id: this.lesson_id_filter, class_id: this.class_id_filter, isFlex: this.selected_asm })
            },

            filterStatus(key){
                this.status = EAssignmentStatus[key]
                this.filterAssigmnets({course_id: this.course_id_filter, buttonFilter: this.activeButtonIndex, searchText: this.keySearch, status: this.status, module_id: this.module_id_filter, lesson_id: this.lesson_id_filter, class_id: this.class_id_filter, isFlex: this.selected_asm })
            },

            changeButtonFilter(fillter) {
                this.activeButtonIndex = fillter;
                this.filterAssigmnets({course_id: this.course_id_filter, buttonFilter: this.activeButtonIndex, searchText: this.keySearch, status: this.status, module_id: this.module_id_filter, lesson_id: this.lesson_id_filter, class_id: this.class_id_filter, isFlex: this.selected_asm })
            },

            clearFilter(){
                this.module_id_filter = 0;
                this.course_id_filter = 0;
                this.lesson_id_filter = 0;
                this.activeButtonIndex = BUTTONS_FILTER.INCOMPLETE
                this.status = EAssignmentStatus.UNKNOW;
                this.keySearch = '';
                this.selected_asm = 0
                this.getAllAssignments();
                // this.assignments = this.rawAssignmnets.slice()

            },
            
            getUnit(id){
                this.$store.commit("setLoading", true);
                const params = {
                    course_id: id,
                };
                axios.all([
                    getUnit1(params),
                ]).then(axios.spread((report_response) => {
                    //   this.modules =  [{module_name: 'module_name_753_hardcode', module_id: 753}, {module_name: 'module_name_754_hardcode', module_id: 754}]
                    //   this.$store.commit("setLoading", false);
                    //   return;
                    
                    this.$store.commit("setLoading", false);
                    if (report_response.data.code != 0) {
                        return;
                    }
                    this.modules = report_response.data.results;

                    this.modules.sort((a, b) => {
                        return a.module_name.localeCompare(b.module_name);
                    });
                }));
            },

            getLesson(course_id, module_id){
                this.$store.commit("setLoading", true);
                const params = {
                    course_id: course_id,
                    module_id: module_id
                };
                axios.all([
                    getLesson(params),
                ]).then(axios.spread((report_response) => {
                    this.$store.commit("setLoading", false);
                    if (report_response.data.code != 0) {
                        return;
                    }
                    this.lessons = report_response.data.results;

                    this.lessons.sort((a, b) => {
                        return a.lesson_name.localeCompare(b.lesson_name);
                    });
                }));
            },

            async getAllCourse(program_id){
                this.$store.commit("setLoading", true);
                const params = {
                    // grade_id: this.user_info.grade_id,
                    program_id: program_id
                };
                await axios.all([
                    getAllCourse(params),
                ]).then(axios.spread((report_response) => {
                    if (report_response.data.code != 0) {
                        return;
                    }

                    this.courses = report_response.data.results
                    this.rawCourses = this.courses
                    this.$store.commit("setLoading", false);
                }));
            },

            async getAllAssignments(){
                this.$store.commit("setLoading", true);
                await axios.all([
                    getAssignments(),
                ]).then(axios.spread((response) => {
                    if (response.data.code != 0) {
                        return;
                    }

                    this.rawAssignmnets = response.data.results.sort((a, b) => {
                        return a.module_order - b.module_order || a.lesson_order - b.lesson_order;
                    });

                    this.assignments = response.data.results

                    this.assignments = this.rawAssignmnets.slice();
                    this.course_id_filter = -1
                    this.class_id_filter = -1
                    this.filterAssigmnets({buttonFilter: this.activeButtonIndex, searchText: this.keySearch, status: this.status, isFlex: this.selected_asm})
                    this.filterTypeAsm({isFlex: this.selected_asm})

                    const courseIdsInAssignments = new Set(this.filterAsm.map(asm => asm.course_id));
                    const filteredCourses = this.rawCourses.filter(c => courseIdsInAssignments.has(c.course_id));

                    this.courses = filteredCourses

                    this.courses.sort((a, b) => {
                        return a.course_name.localeCompare(b.course_name);
                    });

                    if (this.assignments.length != 0) {
                        // const A1 = [...this.assignments].sort((a, b) => {
                        //     return a.course_name.localeCompare(b.course_name);
                        // });

                        // const A2 = [...this.assignments].sort((a, b) => {
                        //     return a.module_name.localeCompare(b.module_name);
                        // });

                        // const A3 = [...this.assignments].sort((a, b) => {
                        //     return a.lesson_name.localeCompare(b.lesson_name);
                        // });

                        // const f_course = A1[0]
                        // const f_module = A2[0]
                        // const f_lesson = A3[0]

                        // this.filterCourse(this.courses.find(element => element.course_id == f_course.course_id && element.class_id == f_course.class_id))
                        // this.filterModule(f_module)
                        // this.filterModule(f_lesson)
                    }

                    this.$store.commit("setLoading", false);
                }));
            },

            filterTypeAsm({isFlex}){
                this.filterAsm = this.rawAssignmnets.filter(function(e) {
                    var condition_type = true
                    condition_type = e.is_flex == isFlex;
                    return condition_type
                })
            },

            filterAssigmnets({course_id, module_id, lesson_id, status, buttonFilter, searchText, class_id, isFlex}) {
                if(buttonFilter == BUTTONS_FILTER.INCOMPLETE){
                    console.log(1);
                    this.rawAssignmnets.sort((a, b) => {
                        let dateA = new Date(a.end_time);
                        let dateB = new Date(b.end_time);
                        return dateA - dateB;
                    });
                }else if(buttonFilter == BUTTONS_FILTER.COMPLETED){
                    console.log(2);
                    this.rawAssignmnets.sort((a, b) => {
                        let dateA = new Date(a.submission_time);
                        let dateB = new Date(b.submission_time);
                        return dateB - dateA;
                    });
                }else{
                    console.log(3);
                    this.rawAssignmnets.sort((a, b) => {
                        if (StatusPriority[a.status] !== StatusPriority[b.status]) {
                            return StatusPriority[a.status] - StatusPriority[b.status];
                        }
                        let dateA = new Date(a.start_time);
                        let dateB = new Date(b.start_time);
                        return dateB - dateA;
                    });
                }

                this.assignments = this.rawAssignmnets.filter(function(e) {
                    console.log( e.status)
                    var condition_course = true;
                    var condition_module = true;
                    var condition_lesson = true;
                    var condition_status = true;
                    var condition_buttonFilter = true;
                    var condition_searchText = true
                    var condition_class = true
                    var condition_type = true
                    if(course_id && course_id >0) {
                        condition_course = e.course_id == course_id;
                    }

                    if(class_id && class_id >0) {
                        condition_class = e.class_id == class_id;
                    }

                    if(module_id && module_id >0) {
                        condition_module = e.module_id == module_id;
                    }
                    if(lesson_id && lesson_id >0) {
                        condition_lesson = e.lesson_id == lesson_id;
                    }
                    if(status && status != EAssignmentStatus.UNKNOW) {
                        condition_status = e.status == status;
                    }
  
                    condition_type = e.is_flex == isFlex;
                    
                    if(buttonFilter) {
                        switch (buttonFilter) {
                            case BUTTONS_FILTER.COMPLETED:
                                condition_buttonFilter = e.status === EAssignmentStatus.SUBMITED || e.status === EAssignmentStatus.COMPLETED;
                                break;
                            case BUTTONS_FILTER.INCOMPLETE:
                                condition_buttonFilter = e.status === EAssignmentStatus.ON_GOING || e.status === EAssignmentStatus.INPROGRESS || e.status === EAssignmentStatus.NEED_RESUBMIT;
                                break;
                            default:
                                condition_buttonFilter = true;
                                break;
                        }
                    }
                    if(searchText) {
                        condition_searchText = (e.content_name.toLowerCase()).includes(searchText.toLowerCase()) ||  (e.asm_name.toLowerCase()).includes(searchText.toLowerCase());
                    }
                    return condition_class && condition_course && condition_module && condition_lesson && condition_status && condition_buttonFilter && condition_searchText && condition_type ;
                })
            },

            handleDocumentClick(event) {
                const isOutsideInputFilter = !event.target.closest(".input-filter");
                if (isOutsideInputFilter) {
                    this.selection_1 = false;
                    this.selection_2 = false;
                    this.selection_3 = false;
                }
            },
            
            showSlection(opt){
                if(opt == 1){
                    this.selection_1 = true
                    this.selection_2 = false
                    this.selection_3 = false
                    this.selection_4 = false
                }else if(opt == 2){
                    this.selection_2 = true
                    this.selection_1 = false
                    this.selection_3 = false
                    this.selection_4 = false
                }else if(opt == 3){
                    this.selection_3 = true
                    this.selection_1 = false
                    this.selection_2 = false
                    this.selection_4 = false
                }
                else if(opt == 4){
                    this.selection_4 = true
                    this.selection_1 = false
                    this.selection_2 = false
                    this.selection_3 = false
                }
            }
        }
    }

    
</script>

<style scoped>
    .no-filter{
        width: 100%;
        padding: 50px;
        color: var(--Pumpkin-Light, #FC7A1B);
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        border-radius: 24px;
        border: 2px solid var(--Pumpkin-Very-Light, #FFE9DA);
        background: var(--Creme-Light, #FFF);
    }
    .no-data{
        color: var(--Liquorice-Light, #777);
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        padding: 50px;
    }
    .img-notstarted{
        opacity: 0.5; /* Làm mờ ảnh */
        -webkit-filter: grayscale(100%); /* Trình duyệt webkit (chrome, safari, ...)*/
        filter: grayscale(100%);
    }
    .item-asm.notstarted{
        background: var(--Cloud-Light, #DDD);
    }
    .item-asm.overdue{
        background: #FFDADE;
    }
    .p-status{
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.6px;
        border-radius: 24px;
        background: var(--Jelly-Very-Light, #EAD4FF);
        padding: 8px;
        height: fit-content;
    }
    .p-status.new{
        background: var(--Jelly-Very-Light, #FFE9DA);
        color: var(--Jelly-Light, #FC7A1B);
    }
    .p-status.inprogress{
        background: var(--Jelly-Very-Light, #E9FFD9);
        color: var(--Jelly-Light, #58CC02);
    }
    .p-status.submitted{
        background: var(--Jelly-Very-Light, #EAD4FF);
        color: var(--Jelly-Light, #A560EB);
    }
    .p-status.notstarted{
        background: var(--Cloud-Dark, #CFCFCF);
        color: var(--Liquorice-Light, #777);
    }
    .p-status.graded{
        background: var(--Jelly-Very-Light, #EAD4FF);
        color: var(--Jelly-Light, #A560EB);
    }
    .p-status.return{
        background: var(--Jelly-Very-Light, #FFE9DA);
        color: var(--Jelly-Light, #FC7A1B);
    }
    .p-status.overdue{
        background: var(--Jelly-Very-Light, #FF3D54);
        color: var(--Jelly-Light, #FFF);
    }
    .item-asm .title{
        color: #000;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.32px;
        margin-bottom: 10px;
    }
    .date{
        color: var(--Liquorice-Light, #777);
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.24px;
    }
    .dot-1{
        width: 8px;
        height: 8px;
        background: #CFCFCF;
        border-radius: 50%;
    }
    .info>p{
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.24px;
    }
    .info{
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;
    }
    .type-asm{
        color: var(--Rasberry-Light, #A560EB);
    }
    .course-title{
        color: var(--Liquorice-Dark, #4C4C4C);
    }
    .path{
        color: var(--Liquorice-Light, #777);
    }
    .list-asm{
        height: calc(100% - 230px);
        overflow: auto;
    }
    .list-asm .item-asm{
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #CFCFCF;
    }

    .index{
        color: var(--Jelly-Light, #A560EB);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.8px;
        margin-right: 10px;
    }
    .index>span{
        color: var(--Pumpkin-Light, #FC7A1B);
    }
    .content{
        border-radius: 24px;
        border: 2px solid var(--Jelly-Very-Light, #EAD4FF);
        background: var(--Creme-Light, #FFF);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
        overflow: hidden;
    }
    .content>.header{
        border-radius: 24px 24px 0px 0px;
        background: var(--Gradient-2, radial-gradient(109.13% 100% at 50% 100%, #CC97EF 0%, #BA53EA 100%));
    }
    .content>.header>p{
        padding: 16px 24px;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.32px;
    }
    .clear{
        color: var(--Cloud-Dark, #CFCFCF);
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.24px;
        justify-self: flex-end;
        margin-top: 20px;
        cursor: pointer;
    }
    .list-choice{
        color: var(--Liquorice-Dark, #4C4C4C);
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.24px;
        border-radius: 4px;
        border: 1px solid var(--Pumpkin-Very-Light, #FFE9DA);
        box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);
        width: 333px;
    }
    .list-choice .item-choice>p{
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
    }
    .list-choice .item-choice.active{
        background-color: #FFE9DA;
    }
    .list-choice .item-choice{
        /* color: var(--Pumpkin-Light, #FC7A1B); */
        color: var(--Liquorice-Dark, #4C4C4C);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.24px;


        height: 40px;
        padding: 16px;
        background: var(--Creme-Light, #FFF);
        cursor: pointer;
        display: flex;
        align-items: center;
        /* border-radius: 4px; */
        /* border: 1px solid var(--Pumpkin-Very-Light, #FFE9DA); */
    }
    .list-choice .item-choice:hover{
        background: var(--Pumpkin-Very-Light, #FFE9DA);
    }
    .filter .label{
        color: var(--squid-black, #1D1D1F);
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.24px;
    }
    .input-filter.course{
        background: #fff;
    }
    .input-filter.disable{
        background: #DDDDDD;
    }
    .input-filter>p{
        color: var(--Liquorice-Light, #777);
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
    }
    .input-filter>.p-active{
        color: var(--Liquorice-Dark, #4C4C4C);
        font-weight: 700;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
    }
    .input-filter{
        border-radius: 8px;
        border: 1px solid var(--Cloud-Dark, #CFCFCF);
        background: #FFF;
        box-shadow: 2px 2px 0px 0px #CFCFCF;
        padding: 8px 16px;
        display: flex;
        width: 200px;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
        cursor: pointer;
    }
    .input-filter.active{
        border: 1px solid var(--Pumpkin-Light, #FC7A1B);
        background: var(--Pumpkin-Very-Light, #FFE9DA);
        box-shadow: 2px 2px 0px 0px #FC7A1B;
    }
    .block-filter{
        gap: 16px;
        display: flex;
        margin-top: 8px;
        border-radius: 24px;
        border: 1px dashed var(--Cloud-Dark, #CFCFCF);
        background: var(--Creme-Dark, #F8FAFC);
        padding: 8px 16px 16px 16px;
        justify-content: space-between;
        margin-bottom: 8px;
    }
    #assignment .right{
        background: #FFFFFF;
        height: 100%;
        /* width: calc(100% - 56px); */
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
        border-radius: 24px 0px 0px 24px;
        padding: 24px;
        overflow: auto;
        flex: 1;
        position: fixed;
        left: 50px;
        right: 0;
    }
    .list-btn{
        display: flex;
        gap: 16px;
    }
    .list-btn>div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .btn{
        text-align: center;
        width: 167px;
        padding: 16px 0;
        border-radius: 24px;
        border: 2px solid var(--Creme-Light, #FFF);
        background: var(--Creme-Dark, #F8FAFC);

        color: var(--squid-black, #1D1D1F);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.32px;
        cursor: pointer;
    }

    .btn.active{
        border-radius: 24px;
        border: 2px solid var(--Pumpkin-Light, #FC7A1B);
        background: var(--Linear-Orange, linear-gradient(180deg, #FF9E44 41.67%, #FF891C 62.5%));
        box-shadow: 0px 0px 16px 0px #FC7A1B;

        color: #FFF;
    }
    .dot{
        fill: var(--Pumpkin-Light, #FC7A1B);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #FC7A1B;
        margin-bottom: 3px;
        position: absolute;
        top: 13px;
    }

    .right>.header>h4{
        color: #000;
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 0.64px;
        /* margin-right: 90px; */
    }
    .right>.header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 18px;
        border-bottom: 1px solid #A9A9A9;
        gap: 15px;
        flex-wrap: wrap;
    }

    .search .form{
        display: flex;
        background: #fff;
        border-radius: 30px;
        height: 40px;
        padding: 8px 8px 8px 16px;
        align-items: center;
        gap: 24px;
        align-self: stretch;

        border-radius: 48px;
        border: 1px solid #191715;
        background: #FFF;
        box-shadow: 4px 4px 0px 0px #191715;
    }
    .search .icon-search{
        width: 16px;
        /* margin:0 16px; */
    }
    .search .form-search{
        outline: none;
        border: none;
        font-size: 12px;
        margin-right: 8px;
    }
    input[type="radio"] {
        transform: scale(1.5);
    }
    label p{
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: left;
        color: #1D1D1F;
    }
    .control-asm{
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top: 20px;
    }
</style>
