<template>
    <div id="subject">
        <div class="right">
            <div class="header">
                <div class="left-header">
                    <p class="desc">You're studying</p>
                    <div class="title-subject">
                        <h4>{{ course_name }}</h4>
                        <img style="cursor: pointer;" src="/themes/web/assets/images/icon/icon-change-course.svg" alt="">
                    </div>
                    <div class="pace" style="display: flex; padding: 4px; align-items: center; border-radius: 16px; background: var(--Creme-Dark, #F8FAFC); justify-content: space-between;">
                        <!-- <p class="p-pace-1">Grades</p> -->
                        <div style="display: flex; justify-content: space-between; padding: 8px; align-items: center;border-radius: 16px; background: var(--Creme-Light, #FFF); gap: 8px">
                            <p class="p-pace">Current Grade</p>
                            <div class="circle-pace"><p>--</p></div>
                        </div>
                        <div style="display: flex; justify-content: space-between; padding: 8px; align-items: center;border-radius: 16px; background: var(--Creme-Light, #FFF); gap: 8px">
                            <p class="p-pace">Course Grade</p>
                            <div class="circle-pace"><p>--</p></div>
                        </div>
                        <div class="icon-info" style="position: relative;">
                            <img src="/themes/web/assets/images/icon/icon-info.svg" alt="">
                            <div class="tool-tip-info">
                                <p>
                                    <b>Current Grade:</b> This is the grade the student has earned for the work completed and scored to date.
                                </p>
                                <p>
                                    <b>Course Grade:</b> This is the final grade the student will earn for the course.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="progress">
                        <!-- { width: moduleCurent.total!=0 ? moduleCurent.process/moduleCurent.total*100 + '%' : 100+'%' } -->
                        <div class="progress-load">
                            <div class="status-pace">
                                <div class="line-pace"></div>
                                <div class="tool-tip-pace">
                                    <p>You’re on good pace.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-header">
                    <div v-for="(module, index) in modules" :key="module.id" @click="changeModule(module, index)">
                        <div style="display: flex; align-items: center; gap: 8px;">
                            <div style="position: relative; width: 80px;">
                                <div :class="module.id == moduleCurent.id ? 'theme-item active' : 'theme-item'">
                                    <img v-if="module.type_code == 'module'" :src="module.module_img" alt="">
                                    <img v-else :src="module.module_img && module.module_img != '' ? module.module_img : default_semeter_img" alt="">
                                </div>
                                <p v-if="module.type_code == 'module'" class="name-theme">Module {{ index + 1 }}</p>
                                <p v-else class="name-theme">Final Test</p>
                            </div>
                            <div v-if="modules.length != index+1" class="dot"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body">
                <div class="content-left" style="width: 30%;">
                    <div class="theme-card">
                        <img :src="course_img != '' ? course_img : '/themes/web/assets/images/default-course.png'" alt="">
                    </div>
                    <div class="unit-details">
                        <!-- <p>Module {{indexModule}}/{{ modules.length }}</p> -->
                        <!-- <h4>{{ course_name }}</h4> -->
                        <div>
                            <p v-if="course_desc" :style="{ whiteSpace: 'pre-wrap' }">
                                {{ displayText }}
                                <span v-if="isTruncated" @click="seeMore()" style="cursor: pointer;">
                                    {{ showMore ? 'See less' : 'See more' }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="moduleCurent.type_code == 'module'" class="content-right" style="width: 70%;">
                    <div class="desc-unit">
                        <h2>{{ moduleCurent?.module_name }}</h2>
                        <div class="number-unit">
                            <div>
                                <img src="/themes/web/assets/images/icon/icon-lesson.svg" alt="">
                                <p><b>{{ moduleCurent?.lessons ? moduleCurent.lessons.length : 0 }}</b> <span>Lessons</span></p>
                            </div>
                            <div>
                                <img src="/themes/web/assets/images/icon/icon-assignment.svg" alt="">
                                <p><b>{{ moduleCurent.total_test }}</b> <span>Assignments</span></p>
                            </div>
                            <div v-if="moduleCurent.desc" @click="showModuleDesc()" style="justify-content: end; flex: 1; cursor: pointer;">
                                <img src="/themes/web/assets/images/icon/icon-info1.svg" alt="">
                            </div>
                        </div>
                        <!-- <p :style="{ whiteSpace: 'pre-wrap' }">{{ moduleCurent.desc }}</p> -->
                    </div>
                    <div class="list-lesson">
                        <div v-for="(lesson, index) in moduleCurent.lessons" :key="lesson.id">
                            <div class="lesson-item">
                                <div class="desc-lesson">
                                    <div class="title-lesson">
                                        <span style="color: #4C4C4C;">Lesson {{ index + 1 }}</span>
                                    </div>
                                    <h4>{{lesson.lesson_name}}</h4>
                                </div>
                                <div class="btn-play">
                                    <p class="status-lesson">Completed</p>
                                    <router-link :to="{ path: '/preview-lesson', query: { course_id, module_id: moduleCurent.id, lesson_id: lesson.id } }">
                                        <img style="width: 90px; cursor: pointer;" src="/themes/web/assets/images/icon/btn-play.png" alt="">
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div v-if="tests && tests.length != 0" class="lesson-item current">
                            <div class="desc-lesson">
                                <div class="title-lesson">
                                    <span style="color: #4C4C4C;" >End of Module</span>
                                </div>
                                <h4>{{tests.test_name}}</h4>
                            </div>
                            <div class="btn-play">
                                <div @click="doTest(tests)">
                                    <img style="width: 90px; cursor: pointer;" src="/themes/web/assets/images/icon/btn-play.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="moduleCurent.type_code == 'end_of_semester'" class="content-right" style="width: 70%">
                    <div class="semeter-test">
                        <h5 v-if="moduleCurent.lock == 1" class="status status-notstarted">Not Started</h5>
                        <h5 v-else-if="semeter_test.status==TestStatus.ON_GOING" class="status status-todo">To do</h5>
                        <h5 v-else-if="semeter_test.status==TestStatus.SUBMITED" class="status status-submitted">Submitted</h5>
                        <h5 v-else-if="semeter_test.status==TestStatus.NEED_RESUBMIT" class="status status-resubmit">Need to Resubmit</h5>
                        <h5 v-else-if="semeter_test.status==TestStatus.COMPLETED" class="status status-completed">Completed</h5>

                        <h2 class="title">End-of-semeter test</h2>
                        <p class="desc-1" v-if="semeter_test.status==TestStatus.COMPLETED && semeter_test.is_pass == 0">
                            You're so close! Don't give up.
                        </p>
                        <div class="message" v-if="moduleCurent.lock == 0">
                            <p v-if="semeter_test.status==TestStatus.ON_GOING && semeter_test.lock == 1">Hi everyone! Just a heads up that a test is coming soon. </p>
                            <p v-else-if="semeter_test.status==TestStatus.ON_GOING && semeter_test.lock == 0">Wishing you all the best on your test!</p>
                            <p v-else-if="semeter_test.status==TestStatus.SUBMITED">Great work on your test! Review your answer while score is coming</p>
                            <p v-else-if="semeter_test.status==TestStatus.COMPLETED">Finished grading! Go see your score.</p>
                            <p v-else-if="semeter_test.status==TestStatus.NEED_RESUBMIT">Almost there! Keep trying.</p>
                        </div>
                        <div class="message" v-else>
                            <p>Ready for the test? Finish all modules first!</p>
                        </div>
                        <button v-if="moduleCurent.lock == 1" class="igs-btn disable">Let's Go</button>
                        <button v-else  @click="semeter_test.lock == 1 ? '' : doTestSemeter(semeter_test)"  :class="semeter_test.lock == 1 ? 'igs-btn disable' : 'igs-btn'">Let's Go</button>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <theme-desc-modal class="" ref="themeDesc"></theme-desc-modal>
        </div>
        <div>
            <change-course-modal @fetch="fetch()" :courseId.sync="course_id" class="" ref="changeCourse"></change-course-modal>
        </div>
    </div>
</template>

<script>
import axios from "../../request";
import {getModule, getModules, getAllCourse} from "../../models/preview";
import { TestStatus} from "../../models/dashboard";
import themeDescModal from "../modal/Learning/themeDescModal.vue";
import changeCourseModal from "../modal/Learning/changeCourseModal.vue";

export default {
    name: "PreviewCourse",
    components: {themeDescModal, changeCourseModal},
    title: 'PreviewCourse',

    data() {
        return{
            TestStatus,
            default_semeter_img: '/themes/web/assets/images/icon/subject/theme-semeter-test.svg',
            semeter_test: '',
            current_path: this.$route.path.split('/')[1],
            showPopup: null,
            course_id: this.$route.query.course_id,
            module_id: this.$route.query.module_id,
            lessons: [],
            modules: [],
            moduleCurent: {},
            lessonCurrent: {},
            indexModule: 1,
            indexLesson: 1,
            showMore: false,
            limit: 120,
            course_name: '',
            course_grade: '',
            current_grade: '',
            courses: [],
            course_desc: '',
            subject_name: '',
            course_img: '',
            pacing: 1,
            total_lessons: 0,
            total_tests: 0,
            is_structured: 1,
            tests: null,
        }
    },

    watch:{
        course_id(){
            this.$store.commit("setLoading", true);
            this.fetch();
        }
    },

    created() {
        // this.$store.dispatch("SET_USER_INFO");
        this.$store.commit("setLoading", true);
    },

    mounted(){
        setTimeout(() => {
        this.fetch();      
        }, 300);
    },

    computed: {
        user_info() {
            return this.$store.state.user_info;
        },
        truncatedText() {
            return this.course_desc.slice(0, this.limit)
        },
        isTruncated() {
            return this.course_desc.length > this.limit
        },
        displayText() {
            return this.showMore || !this.isTruncated ? this.course_desc : this.truncatedText + '...'
        }
    },

    methods: {
        fetch(page = 1) {
            // this.getAllCourse();
            this.getModules();
        },
        async showModuleDesc(){
            const cfm = await this.$refs.themeDesc.show({
                type: 2,
                lang: this.lang,
                desc: this.moduleCurent.desc,
            });
            if (!cfm) {
                return false;
            }
        },
        showToolTipModule(e){

        },
        doTest(test){
            // this.$router.push({ name: 'test_detail_preview', params: { test } });

            this.$router.push({ 
                name: 'test_detail_preview', 
                query: { 
                    test: JSON.stringify(test), 
                    type: 'end_of_module',
                    class_id: this.class_id, 
                    course_id: this.course_id, 
                    module_id: this.moduleCurent.id 
                } 
            });
        },
        doTestSemeter(test){
            this.$router.push({ 
                name: 'test_detail_preview', 
                query: { 
                    test: JSON.stringify(test), 
                    type: 'end_of_semester',
                    class_id: this.class_id, 
                    course_id: this.course_id, 
                    module_id: this.moduleCurent.id 
                } 
            });
        },
        showStatusPace(n){
            if(n == 1){
                $(".tool-tip-pace").css({
                    left: - $(".tool-tip-pace").width()/2 + 10,
                    display: "block",
                    background: "rgba(35, 118, 168, 1)",
                });
            }else if(n == 2){
                $(".tool-tip-pace").css({
                    left: - $(".tool-tip-pace").width()/2 + 10,
                    display: "block",
                    background: "rgba(189, 43, 17, 1)",
                });
            }else if(n == 3){
                $(".tool-tip-pace").css({
                    left: - $(".tool-tip-pace").width()/2 + 10,
                    display: "block",
                    background: "rgba(39, 174, 96, 1)",
                });
            }
           
        },
        hideStatusPace(){
            $(".tool-tip-pace").css({
                display: "none",
            });
        },
        getAllCourse(){
            const params = {
                grade_id: this.user_info.grade_id,
                program_id: 0
            };
            getAllCourse(params).then(report_response => {
                if (report_response.data.code != 0) {
                    return;
                }
                this.courses = report_response.data.results
            });
        },
        async seeMore(){
            const cfm = await this.$refs.themeDesc.show({
                type: 1,
                lang: this.lang,
                index: this.indexModule,
                all_index: this.modules.length,

                total_lessons: this.total_lessons,
                total_tests: this.total_tests,

                course_name: this.course_name,
                subject_name: this.subject_name,
                course_img: this.course_img,
                course_desc: this.course_desc,
            });
            if (!cfm) {
                return false;
            }
        },
        async changeCourse(){
            const cfm = await this.$refs.changeCourse.show({
                lang: this.lang,
                courses: this.courses,
                id_current_course: this.course_id
            });
            if (!cfm) {
                return false;
            }
            return 
        },
        toggleShowMore() {
            this.showMore = !this.showMore
        },
        goHome(){
            $('#subject').css('transform','translateX(0)')
        },
        getModules(){
            const params = {
                course_id: this.course_id,
                module_id: this.module_id
            };
            getModules(params).then(report_response => {
                if (report_response.data.code != 0) {
                    return;
                }

                const rs = report_response.data.results

                this.modules = rs.modules || []
                this.course_name = rs.course_name
                this.course_img = rs.illustration_url
                this.course_desc = rs.desc
                this.subject_name = rs.subject_name
                this.total_lessons = rs.total_lesson
                this.course_grade = rs.course_grade
                this.current_grade = rs.current_grade
                this.pacing = rs.pacing
                this.is_structured = rs.is_structured

                this.lessons = this.modules[0]?.lessons || []
                this.tests = this.modules[0]?.test || []
                const index = this.modules.findIndex(item => item.id == this.module_id);
                console.log(index, this.module_id);
                this.moduleCurent = this.modules[index == -1 ? 0 : index] || {}
                this.lessonCurrent = this.moduleCurent?.lessons ? this.moduleCurent?.lessons[0] : ''
                this.$store.commit("setLoading", false);
            });
        },
        changeModule(module, index){
            this.moduleCurent = module || {}
            this.semeter_test = module.semeter_test
            this.tests = module?.test || null
            this.lessonCurrent = this.moduleCurent?.lessons ? this.moduleCurent?.lessons[0] : ''
            this.indexModule = index + 1
            this.lessons = this.moduleCurent?.lessons
        },
        changeLesson(lesson, index){
            this.lessonCurrent = lesson
            this.indexLesson = index + 1
        }
    }
}
</script>

<style scoped>
    .igs-btn.disable{
        border: 1px solid var(--Cloud-Dark, #CFCFCF);
        background: var(--Cloud-Light, #DDD);
        box-shadow: 4px 4px 0px 0px #CFCFCF;
    }
     .semeter-test{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 200px 40px;
    }
    .semeter-test .desc-1{
        font-size: 16px;
        margin-bottom: 20px;
        font-weight: 600;
        letter-spacing: 0.02em;
        text-align: center;
        color: #FF3D54;
    }
    .semeter-test .title{
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: center;
        color: #4C4C4C;
        margin-bottom: 20px;
    }
    .semeter-test .message{
        /* width: 524px; */
        padding: 16px 24px 16px 24px;
        gap: 16px;
        border-radius: 16px;
        border: 1px solid #DDDDDD;
        background: #F8FAFC;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.02em;
        color: #777777;
        margin-bottom: 18px;
        /* text-wrap: nowrap; */
    } 
    .semeter-test .status{
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: center;
        margin-bottom: 20px;
        padding: 8px 16px 8px 16px;
        border-radius: 24px;
    }
    .semeter-test .status-notstarted{
        color: #777777;
        background: var(--Cloud-Light, #DDD);
    }
    .semeter-test .status-todo{
        color: #FC7A1B;
        background-color: #FFE9DA;
    }
    .semeter-test .status-submitted{
        color: #A560EB;
        background-color: #EAD4FF;
    }
    .semeter-test .status-completed{
        color: #A560EB;
        background-color: #EAD4FF;
    }
    .semeter-test .status-resubmit{
        color: #FC7A1B;
        background-color: #FFE9DA;
    }
    .free-study .theme-item.completed{
        border-radius: 24.75px;
        border: 2px solid rgba(88, 204, 2, 0.80);
    }
    .free-study .theme-item.active .info-progress{
        background: rgba(165, 96, 235, 0.80);
    }
    .free-study .theme-item.ongoing{
        border-radius: 24.75px;
        /* border: 2px solid #A35CDB; */
        border: 2px solid rgba(2, 166, 231, 0.80);
    }
    .free-study .theme-item.not-start{
        border: 2px solid var(--Cloud-Dark, #CFCFCF);
    }
    .free-study .info-progress{
        border-radius: 0px 0px 0px 8px;
        height: 24px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .free-study .info-progress p{
        color: var(--Creme-Light, #FFF);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 200% */
    }
    .free-study .info-progress.completed{
        background: rgba(88, 204, 2, 0.80);
    }
  
    .free-study .info-progress.not-start{
        background: rgba(207, 207, 207, 0.80);
    }

    .free-study .info-progress.inprogress{
        background: rgba(2, 166, 231, 0.80);
    }


    .popper-1{
        z-index: 999 !important;
        /* transform: none !important; */
        left: 225px !important;
        top: 0 !important;
    }
    .popper{
        top: 20px !important;
        z-index: 999 !important;
    }
    .icon-info:hover .tool-tip-info{
        display: block;
    }
    .tool-tip-info{
        display: none;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.90);
        padding: 8px 16px;
        position: absolute;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
        width: 320px;
        top: 30px;
        left: -150px;
        z-index: 99;
    }
    .tool-tip-info p{
        color: var(--Liquorice-Light, #777);
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .tool-tip-info p:nth-child(1){
        margin-bottom: 10px;
    }
    .tool-tip-pace{
        position: relative;
        border-radius: 24px;
        width: fit-content;
        display: none;
        top: 25px;
    }
    .tool-tip-pace p{
        color: var(--Creme-Light, #FFF);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 8px 16px;
        text-wrap: nowrap;
    }
    .progress {
        margin-top: 10px;
        position: relative;
        opacity: 1;
        border-radius: 5px;
        height: 10px;
        width: 100%;
        padding: 2px;
    }
    .progress-load {
        border-radius: 80px;
        transition: none 0s ease 0s;
        height: 10px;
        position: relative;
        width: 100%;
        background: var(--Cloud-Light, #DDD);
    }
    .status-pace{
        cursor: pointer;
        width: 24px;
        height: 16px;
        border-radius: 1000px;
        background: #777;
        position: relative;
        left: 50%;
        top: -3px;
        /* bottom: -12px; */
    }

    .status-pace.slow{
        background: #FF4141;
        width: 40px;
        border-radius: 1000px 0 0 1000px;
    }

    .status-pace.fast{
        background: #58CC02;
        width: 40px;
        border-radius: 0 1000px 1000px 0;
    }

    .line-pace{
        width: 1px;
        height: 24px;
        position: absolute;
        border-right: 2px dotted #1D1D1F;
        left: 50%;
        transform: translateX(-50%);
        top: -4px;
    }
    .line-pace.slow{
        left: 100%;
    }
    .line-pace.fast{
        left: 0%;
    }
    .p-pace-1{
        color: var(--Liquorice-Dark, #4C4C4C);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .p-pace{
        color: var(--Liquorice-Dark, #4C4C4C);
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .circle-pace-1{
        width: 30px;
        height: 30px;
        padding: 8px;
        display: flex;
        align-items: center;
        border-radius: 100px;
        border-radius: 100px;
        border: 1px solid var(--Cloud-Dark, #CFCFCF);
        background: var(--Cloud-Light, #DDD);
        justify-content: center;
    }
    .circle-pace{
        width: 30px;
        height: 30px;
        padding: 8px;
        display: flex;
        align-items: center;
        border-radius: 100px;
        border: 1px solid var(--Cloud-Dark, #CFCFCF);
        background: var(--Cloud-Light, #DDD);
        justify-content: center;
    }
    .circle-pace p{
        color: var(--Kiwi-Light, #777);
        font-size: 7px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.2px;
    }
    .circle-pace-1 p{
        color: var(--Blueberry-Light, #02A6E7);
        font-size: 7px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.2px;
    }
    .popup {
        position: absolute;
        background-color: red;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        /* Định vị popup */
        right: 0;
        top: 0;
    }
    #subject{
        transition: all 0.5s !important;
        width: 100%;
        display: flex;
        height: 100%;
        justify-content: space-between;
    }
    #subject >.right{
        background: #FFFFFF;
        height: 100%;
        /* width: calc(100% - 56px); */
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
        border-radius: 24px 0px 0px 24px;
        padding: 32px 24px;
        overflow: auto;
        flex: 1;
        position: fixed;
        left: 0;
        right: 0;
    }
    #subject >.left{
        width: 56px;
    }
    .btn-close{
        margin-top: 120px;
        width: 32px;
        height: 124px;
        background: #02A6E7;
        border: 1px solid #2376A8;
        border-radius: 24px 0px 0px 24px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .btn-close img{
        transform: rotate(180deg);
    }
    .header .desc{
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #4C4C4C;
        margin-bottom: 4px;
    }
    .header{
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #CFCFCF;
        margin-bottom: 26px;
        /* height: 160px; */
    }
    .header .title-subject{
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #4C4C4C;
        display: flex;
        border: 2px solid rgba(76, 76, 76, 0.25);
        background: rgba(119, 119, 119, 0.25);
        border-radius: 16px;
        align-items: center;
        padding: 4px 8px;
        justify-content: space-between;
        margin-bottom: 4px;
    }
    .header .title-subject h4{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 20px;
    }
    .left-header{
        display: flex;
        flex-direction: column;
        width: 30%;
        padding-right: 16px;
        border-right: 1px solid #CFCFCF;
    }
    .right-header{
        display: flex;
        gap: 8px;
        width: 70%;
        margin-left: 16px;
        height: 100%;
        align-items: center;
        /* overflow-x: auto; */
    }
    .right-header > div{
        display: flex;
        gap: 15px;
        align-items: center;
    }
    .left-header .desc{
        font-weight: 400;
        font-size: 12px;
        color: #4C4C4C;
        opacity: 0.6;
        letter-spacing: 0.02em;
    }
    .theme-item{
        position: relative;
        height: 80px;
        width: 80px;
        border: 4px solid #CFCFCF;
        border-radius: 24.75px;
        /* background-color: #CFCFCF; */
        position: relative;
        overflow: hidden;
        cursor: pointer;
        padding: 2px;
    }
    .tool-tip-complete{
        border-radius: 24px;
        background: var(--Rasberry-Light, #FF3D54);
        padding: 16px;
        width: 320px;
        /* display: none; */
    }
    .tool-tip-complete p{
        color: var(--Creme-Light, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .theme-item.active{
        border: 4px solid #A35CDB;
    }

    .theme-item > img:nth-child(1){
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }
    .icon-status{
        position: absolute;
        right: -2px;
        top: -2px;
        z-index: 2;
    }
    .dot{
        background: #CFCFCF;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
    .dot.active{
        background: #58CC02;
    }
    .name-theme{
        color: #4C4C4C;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        margin-top: 2px;
        white-space: nowrap;
    }
    .body{
        display: flex;
        width: 100%;
        /* gap: 180px; */
        /* background-color: red; */
    }
    .content-left .theme-card > img{
        border: 1px solid #777777;
        /* filter: drop-shadow(0px 8px 0px #4F4F4F) drop-shadow(8px 0px 4px rgba(0, 0, 0, 0.25)); */
        border-radius: 8px;
        width: 100%;
        aspect-ratio: 3/2;
        /* height: 200px; */
    }
    .content-left .theme-card{
        position: relative;
        width: 100%;
        padding-right: 20px;
    }
    .status-unit-item{
        width: 54px;
        height: 32px;
        border-width: 1px 1px 4px 0px;
        border-style: solid;
        border-radius: 0px 16px 16px 0px;
    }
    .status-unit-item.lock{
        background: #DDDDDD;
        border-color: #777777;
    }
    .status-unit-item.checked{
        background: rgba(88, 204, 2, 0.8);
        border-color: #27AE60;
    }
    .status-unit-item.unlock{
        background: radial-gradient(109.13% 100% at 50% 100%, #CC97EF 0%, #BA53EA 100%);
        border-color: #8549BA;
    }
    .status-unit-list{
        position: absolute;
        z-index: 2;
        left: 300px;
        top: 15px;
        display: flex;
        flex-direction: column;
    }
    .status-desc{
        display: flex;
        gap: 8px;
        cursor: pointer;
        height: 37px;
        transition: all 0.2s;
        width: 55px;
    }
    .status-desc.active{
        width: 120px;
        color: #fff !important;
        gap: 0;
    }
    .status-desc.active span{
        padding: 0;
    }
    .status-desc span{
        font-weight: 700;
        font-size: 16px;
        white-space: nowrap;
        align-items: center;
        display: flex;
        padding-right: 16px;
    }
    .status-desc > div{
        margin-left: 8px;
        min-width: 45px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .status-desc img{
        /* width: 100%; */
    }

    .status-desc.checked{
        color: #4C4C4C;
        border-radius: 0px 16px 16px 0px;
        border-top: 1px solid #27AE60;
        border-right: 1px solid #27AE60;
        border-bottom: 4px solid #27AE60;
        background: rgba(88, 204, 2, 0.80);
    }
    .status-desc.unlock{
        color: #A560EB;
        border-radius: 0px 16px 16px 0px;
        border-top: 1px solid var(--jelly-dark, #8549BA);
        border-right: 1px solid var(--jelly-dark, #8549BA);
        border-bottom: 4px solid var(--jelly-dark, #8549BA);
        background: var(--gradient-2, radial-gradient(109.13% 100% at 50.00% 100.00%, #CC97EF 0%, #BA53EA 100%));
    }
    .status-desc.lock{
        color: #CFCFCF;
        border-radius: 0px 16px 16px 0px;
        border-top: 1px solid var(--liquorice-light, #777);
        border-right: 1px solid var(--liquorice-light, #777);
        border-bottom: 4px solid var(--liquorice-light, #777);
        background: var(--cloud-light, #DDD);
    }
    .content-right{
        /* margin-left: 125px; */
        border: 2px solid #EAD4FF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .desc-unit{
        background: radial-gradient(109.13% 100% at 50% 100%, #CC97EF 0%, #BA53EA 100%);
        padding: 24px 24px 40px 24px;
        border-radius: 24px 24px 0px 0px;
    }
    .number-unit{
        display: flex;
        gap: 16px;
        margin-bottom: 16px;
    }
    .number-unit > div{
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .number-unit > div span{
        font-size: 16px;
        letter-spacing: 0.02em;
        font-weight: 300;
    }
    .desc-unit h2{
        font-weight: 700;
        font-size: 32px;
        letter-spacing: 0.02em;
        margin-bottom: 18px;
    }
    .desc-unit > p{
        letter-spacing: 0.02em;
        font-weight: 100;
        font-size: 16px;
    }
    .lesson-item{
        height: 80px;
        padding: 0 17px;
        display: flex;
        align-items: center;
        justify-content: space-between
    }
    .list-lesson{
        max-height: 320px;
        overflow-y: auto;
    }
    .img-lesson{
        min-height: 64px;
        min-width: 64px;
        border-radius: 16px;
        margin-right: 16px;
        position: relative;
    }
    .img-lesson img{
        width: 64px;
        height: 64px;
    }
    .status-lesson{
        letter-spacing: 0.05em;
        font-weight: 700;
        font-size: 16px;
        white-space: nowrap;
    }
    .lesson-item.completed .status-lesson{
        color: #58CC02;
    }
    .lesson-item.current .status-lesson{
        color: #FDD20D;
    }
    .lesson-item.notstart .status-lesson{
        color: #777777;
    }
    .id-lesson{
        letter-spacing: 0.05em;
        font-weight: 700;
        font-size: 20px;
        margin-right: 17px;
    }
    .lesson-item.completed .id-lesson{
        color: #4C4C4C;
    }
    .lesson-item.current .id-lesson{
        color: #A35CDB;
    }
    .lesson-item.notstart .id-lesson{
        color: #777777;
    }
    .lesson-item.notstart{
        background: #EAD4FF;
        opacity: 0.25;
    }
    .btn-play{
        display: flex;
        gap: 16px;
        justify-content: flex-end;
        align-items: center;
        margin-left: 90px;
    }

    .title-lesson{
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        font-weight: 700;
        font-size: 16px;
        gap: 8px;
        margin-bottom: 4px;
    }

    .lesson-item.notstart  .title-lesson{
        color: #777777;
    }
    .lesson-item.current  .title-lesson{
        color: #A35CDB;
    }
    .lesson-item.completed  .title-lesson{
        color: #4C4C4C;
    }
    .desc-lesson{
        max-width: calc(100% - 250px);
    }

    .desc-lesson h4{
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.02em;
        color: #000000;
        /* white-space: nowrap; */
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-y: auto;
        max-height: 47px;
    }
    .unit-details{
        margin-top: 24px;
        width: 300px;
    }
    .unit-details > p {
        color: #777777;
        opacity: 0.6;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.02em;
        margin-bottom: 8px;
    }
    .unit-details > h4 {
        color: #4C4C4C;
        letter-spacing: 0.02em;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 8px;
    }
    .unit-details > div > p {
        color: #4C4C4C;
        letter-spacing: 0.02em;
        font-weight: 100;
        font-size: 16px;
    }

    .unit-details > div span{
      color: #02A6E7;
      font-weight: 900;
      text-decoration: underline;
      cursor: pointer;
    }

    .unlock-new-unit{
        height: 96px;
        width: 100%;
        background: #A560EB;
        padding: 0 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0 0 24px 24px;
    }
    .unlock-new-unit h4{
        width: 100%;
        margin-left: 50px;
    }
    .img-inactive{
        opacity: 0.5; /* Làm mờ ảnh */
        -webkit-filter: grayscale(100%); /* Trình duyệt webkit (chrome, safari, ...)*/
        filter: grayscale(100%);
    }
</style>
