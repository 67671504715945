'use strict';

import Vue from 'vue'
import Vuex from 'vuex'
import VueContentPlaceholders from 'vue-content-placeholders'
import { authLogout } from './models/auth'
import { getProfile, getAvatars} from './models/profile'
import axios from "axios"
import app from './main'
import { convertSecondToText, toCommas } from "./utils/utils";

// https://momentjscom.readthedocs.io/en/latest/moment/06-i18n/01-changing-locale/
moment.locale('vi', {
    months: 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_'),
    monthsShort: 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_'),
    weekdaysShort: 'Chủ nhật_Thứ 2_Thứ 3_Thứ 4_Thứ 5_Thứ 6_Thứ 7'.split('_'),
    relativeTime: {
        future: 'Trong %s',
        past: 'Cách đây %s',
        s: 'vài giây',
        m: '1 phút',
        mm: '%d phút',
        h: '1 giờ',
        hh: '%d giờ',
        d: '1 ngày',
        dd: '%d ngày',
        M: '1 tháng',
        MM: '%d tháng',
        y: '1 năm',
        yy: '%d năm'
    },
});

Vue.use(Vuex);
Vue.use(VueContentPlaceholders);

Vue.filter('toCurrency', function(value, currency) {
    const num = new Intl.NumberFormat('us-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
    }).format(parseFloat(value));

    return num.replace('US$', '').replace('$', '') + '$';
});

Vue.filter('formatDate', function(value, format = 'HH:mm DD/MM/YYYY') {
    if (value) {
        return moment(String(value)).format(format)
    }
});

Vue.filter('formatDate_1', function(value, format = 'DD MMMM YYYY') {
    if (value) {
        return moment(value, 'YYYY/MM/DD').locale('en').format(format).toUpperCase();
    }
});

Vue.filter('convertUnitToTime', function(time, format = 'DD MMM, YYYY HH:mm:ss') {
    if (time) {
        const lang = localStorage.getItem('lang') || 'vi';

        return moment.unix(time).locale(lang).format(format)
    }

    return '';
});

Vue.filter('convertUnitToTime_1', function(time, format = 'DD MMM, YYYY - HH:mm:ss') {
    if (time) {
        const lang = localStorage.getItem('lang') || 'vi';

        return moment.unix(time).locale(lang).format(format)
    }

    return '';
});

Vue.filter('convertSecondToCountdown', function(time) {
    if (time) {
        const current_time = Math.floor(Date.now() / 1000);
        const countdown = time - current_time;

        console.log(countdown, time, current_time)

        if (countdown <= 0) {
            return "00:00:00";
        }

        return convertSecondToText(countdown)
    }

    return '';
});

Vue.filter('toCommas', function(value) {
    if (value) {
        return toCommas(value)
    }

    return '0';
});

//axios.defaults.withCredentials = true;

// axios.interceptors.response.use(function(response) {
//     // Do something with response data
//     // console.log(response);

//     if (response.data.success == false &&
//         response.data.error.code == 1200 &&
//         window.location.pathname != '/login') {
//         console.log('Auth require from store 48')

//         window.location.href = "/login"
//     }

//     return response;
// }, function(error) {
//     // Do something with response error
//     console.log(error);

//     return Promise.reject(error);
// });

export const store = new Vuex.Store({
    state: {
        // layout: 'app-layout',
        layout: 'loading-layout',
        locale: 'en',
        is_login: localStorage.getItem('is_login') || false,
        user_info: {
            name: 'Guest',
            profile: {
                fullname: ''
            },
            menus: [],
            balances: [],
        },
        configs: JSON.parse(localStorage.getItem('configs')),
        alert: {
            is_show: false,
            type: 'error',
            message: ''
        },
        is_loading: false,
        menu_active: 'home',
        role_type: 'student',
        hide_popup: false,
        first_login: false,
        live_classes: [],
        filter_class: '',
        current_course: 0,
        is_cms_preview: false,
        show_menu: true,
        module_id: 0,
        class_id: 0,
        course_id: 0,
        currentModule: {},
        previousRoute: null,
        show_warning: true,
    },

    mutations: {
        setPreviousRoute(state, route) {
            state.previousRoute = route;
        },
        
        SET_CURRENT_COURSE(state, payload) {
            state.layout = payload
        },

        SET_LAYOUT(state, payload) {
            state.layout = payload
        },

        SET_ROLE_TYPE(state, payload) {
            state.role_type = payload;
            localStorage.setItem('role_type', payload);
        },

        SET_CHECK_POPUP(state, payload) {
            state.hide_popup = payload;
            localStorage.setItem('hide_popup', payload);
        },

        SET_FIRST_LOGIN(state, payload) {
            state.first_login = payload;
            localStorage.setItem('first_login', payload);
        },

        SET_USER_INFO(state, payload) {
            state.user_info = payload;
        },

        SET_LANG(state, locale) {
            app.$i18n.locale = locale
        },

        setAlert(state, alert) {
            state.alert = alert;
        },

        setLoading(state, isLoading) {
            state.is_loading = isLoading;
        },

        // setCurrentModule(state, moduleId, courseId, classId) {
        //     state.currentModule = {module_id: moduleId, class_id: classId, course_id: courseId}
        // },

        setCurrentModule(state, { moduleId, courseId, classId }) {
            state.currentModule = { module_id: moduleId, class_id: classId, course_id: courseId };
        },

        setShowMenu(state, showMenu) {
            state.show_menu = showMenu;
        },

        setShowWarning(state, showWarning) {
            state.show_warning = showWarning;
        },

        setConfigs(state, configs) {
            state.configs = configs;
        },

        setMenuActive(state, menu) {
            console.log(menu)

            state.menu_active = menu;
        },

        setFilterClass(state, filter) {
            state.filter_class = filter;
        },

        setAuth(state, isLogin) {
            state.is_login = isLogin;

            localStorage.setItem('is_login', isLogin);
            localStorage.removeItem("child_account");
        },

        auth_success(state, access_token, user_info) {
            state.user_info = user_info;
        },

        auth_error(state) {

        },

        logout(state) {
            state.user_info = {
                name: 'Guest'
            };
            state.is_login = false;

            localStorage.removeItem('is_login');
            localStorage.removeItem("grade");
            localStorage.removeItem("role_type");
            localStorage.removeItem("child_account");
            localStorage.removeItem("access_token");
        },

        setLiveClasses(state, live_classes) {
            state.live_classes = live_classes;
        },
        SET_CMS_PREVIEW_STATE(state, is_cms_preview) {
            state.is_cms_preview = is_cms_preview;
        },
    },

    actions: {
        SET_USER_INFO: async({ commit }) => {
            console.log("heelo");
            if (window.location.pathname != '/login') {
                await axios.all([
                    getProfile({
                        notification_id: window.localStorage.getItem('firebaseToken'),
                        type_code: 'web'
                    })
                ]).then(axios.spread(({ data }) => {
                    commit("setLoading", false);

                    commit("SET_LAYOUT", 'app-layout');

                    if (data.code != 0) {
                        localStorage.removeItem('is_login');
                        localStorage.removeItem('grade');
                        localStorage.removeItem("child_account");

                        window.location.href = "/";
                        return;
                    }
                    const user_info = data.results;
                    user_info.avatar_url = user_info.avatar_url || '/themes/web/assets/images/icon/avatar-default2.png';

                    // axios.all([
                    //     getAvatars({
                    //         id: user_info.nosql_id,
                    //         url: process.env.VUE_APP_CMS_API,
                    //     })
                    // ]).then(axios.spread(({ data }) => {
                    //     user_info.avatar_url = data.data || '/themes/web/assets/images/icon/avatar-default2.png';
                    // }))

                    commit("SET_USER_INFO", user_info);
                    console.log("user_info", user_info, process.env.VUE_APP_CMS_LMS_API);
                    localStorage.setItem('student_code',user_info.student_code);
                    localStorage.setItem('timezone',user_info.timezone);

                })).catch(error => {
                    console.log(error)
                    commit('logout');
                });



               
                
            }
        },

        SET_CURRENT_COURSE({commit}, current_course){
            commit('SET_CURRENT_COURSE', current_course);
        },

        setLiveClasses({ commit }, live_classes) {
            commit('setLiveClasses', live_classes);
        },

        SET_ROLE_TYPE({ commit }, role_type) {
            commit('SET_ROLE_TYPE', role_type);
        },

        SET_CHECK_POPUP({ commit }, hide_popup) {
            commit('SET_CHECK_POPUP', hide_popup);
        },

        SET_FIRST_LOGIN({ commit }, first_login) {
            commit('SET_CHECK_POPUP', first_login);
        },

        // setCurrentModule({ commit }, { moduleId, courseId, classId }) {
        //     commit('SET_MODULE_ID', { moduleId, courseId, classId });
        // },

        setCurrentModule({ commit }, { moduleId, courseId, classId }) {
            commit('setCurrentModule', { moduleId, courseId, classId });
        },

        setLang({ commit }, locale) {
            commit('SET_LANG', locale)
        },

        setAlert({ commit }, alert) {
            commit('setAlert', alert);
        },

        setLoading({ commit }, isLoading) {
            commit('setLoading', isLoading);
        },

        setShowMenu({ commit }, showMenu) {
            commit('setShowMenu', showMenu);
        },

        setShowWarning({ commit }, showWarning) {
            commit('setShowWarning', showWarning);
        },

        setConfigs({ commit }, configs) {
            commit('setConfigs', configs);
        },

        setMenuActive({ commit }, menu) {
            commit('setMenuActive', menu);
        },

        setFilterClass({ commit }, filter) {
            commit('setFilterClass', filter);
        },

        setAuth({ commit }, isAuth) {
            commit('setAuth', isAuth);
        },

        logout({ commit }) {
            authLogout().then(({ data }) => {
                commit('logout');

                localStorage.removeItem('is_login');
                localStorage.removeItem('grade');
                localStorage.removeItem("child_account");
            });
        },  
        SET_CMS_PREVIEW_STATE({ commit }, is_cms_preview) {
            commit('SET_CMS_PREVIEW_STATE', is_cms_preview);
        },
    },

    getters: {
        currentCourse(state) {
            return state.current_course
        },

        getCurrentModule(state) {
            return state.currentModule;
        },

        isLoggedIn(state) {
            return state.is_login;
        },

        layout(state) {
            return state.layout
        },

        isLoading(state) {
            return state.is_loading
        },

        isShowMenu(state) {
            return state.show_menu
        },

        isShowWarning(state) {
            return state.show_warning
        },

        menuActive(state) {
            return state.menu_active
        },

        alert(state) {
            return state.alert
        },

        user_info(state) {
            return state.user_info
        },

        role_type(state) {
            return state.role_type
        },

        configs(state) {
            return state.configs
        },

        live_classes(state) {
            return state.live_classes
        },

        filter_class(state) {
            return state.filter_class
        },
        
        isCmsPreview(state) {
            return state.is_cms_preview
        },
    }
});