var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"lesson"}},[_c('list-item',{ref:"listItem",on:{"openItem":_vm.openItem}}),(_vm.current_categories && _vm.current_categories.package_type == 1)?_c('div',{staticClass:"header"},[(_vm.current_categories.items.length > 1)?_c('div',{staticClass:"btn-menu small",staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.showListItem()}}},[(_vm.showMenu)?_c('img',{attrs:{"src":"/themes/web/assets/images/icon/icon-menu.svg","alt":""}}):_c('img',{attrs:{"src":"/themes/web/assets/images/icon/icon-x.svg","alt":""}}),(!_vm.showMenu)?_c('div',{staticClass:"tools"},_vm._l((_vm.catagory.items),function(item){return _c('div',{key:item.id},[_c('div',{class:item.lock == 1 ? 'locked' : '',staticStyle:{"display":"flex","gap":"10px","margin-bottom":"10px"},on:{"click":function($event){item.lock == 0 ? _vm.openItem(item) : ''}}},[(item.id == _vm.learning_item.id)?_c('img',{attrs:{"src":"/themes/web/assets/images/icon/arrow-left-orange.svg","alt":""}}):(item.status == 1)?_c('img',{attrs:{"src":"/themes/web/assets/images/icon/checked-item.svg","alt":""}}):(item.status == 2 && item.is_structured)?_c('img',{staticStyle:{"margin-top":"3px"},attrs:{"src":"/themes/web/assets/images/icon/item-new.svg","alt":""}}):_vm._e(),_c('p',{class:item.id == _vm.learning_item.id ? 'active' : ''},[_vm._v(" "+_vm._s(item.name)+" ")])])])}),0):_vm._e()]):_vm._e(),(_vm.current_categories.items.length > 1)?_c('div',[_c('button',{class:_vm.learning_item.index != 0
            ? 'igs-btn small'
            : 'igs-btn small inactive',staticStyle:{"margin-right":"8px"},attrs:{"disabled":_vm.isButtonDisabled},on:{"click":function($event){_vm.learning_item.index != 0 ? _vm.pre(_vm.learning_item) : ''}}},[_c('img',{attrs:{"src":"/themes/web/assets/images/icon/arrow-left.svg","alt":""}})]),_c('button',{class:_vm.current_categories.items &&
          _vm.learning_item.index + 1 != _vm.current_categories.items.length &&
          _vm.current_categories.items[_vm.learning_item.index + 1].lock == 0
            ? 'igs-btn small'
            : 'igs-btn small inactive',attrs:{"disabled":_vm.isButtonDisabled},on:{"click":function($event){_vm.current_categories.items &&
          _vm.learning_item.index + 1 != _vm.current_categories.items.length &&
          _vm.current_categories.items[_vm.learning_item.index + 1].lock == 0
            ? _vm.next(_vm.learning_item)
            : ''}}},[_c('img',{attrs:{"src":"/themes/web/assets/images/icon/arrow-right.svg","alt":""}})])]):_vm._e(),(_vm.current_categories.items.length > 1)?_c('img',{staticClass:"icon-arow",attrs:{"src":"/themes/web/assets/images/icon/icon-arrow-right.svg","alt":""}}):_vm._e(),_c('p',{staticClass:"path"},[_vm._v(_vm._s(_vm.current_categories.name))]),_c('img',{staticClass:"icon-arow",attrs:{"src":"/themes/web/assets/images/icon/icon-arrow-right.svg","alt":""}}),_c('p',{staticClass:"path-1"},[_vm._v(_vm._s(_vm.learning_item.name))]),_c('router-link',{staticClass:"btn-close",attrs:{"to":{
        path:
          '/lesson-package/' +
          _vm.class_id +
          '/' +
          _vm.course_id +
          '-' +
          _vm.module_id +
          '-' +
          _vm.lesson_id,
      }}},[_c('img',{attrs:{"src":"/themes/web/assets/images/icon/icon-x.svg","alt":""}}),_c('p',[_vm._v("Close")])])],1):_vm._e(),_c('div',{class:_vm.current_categories && _vm.current_categories.package_type == 1
        ? 'webview'
        : 'webview test'},[_c('iframe',{attrs:{"allow":"microphone *;clipboard-read *; clipboard-write *","src":_vm.learning_item ? _vm.learning_item.content : '',"frameborder":"0","allowfullscreen":"","width":"100%","height":"100%"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }