<template>
  <div id="lesson">
    <list-item @openItem="openItem" class="" ref="listItem"></list-item>
    <div
      class="header"
      v-if="current_categories && current_categories.package_type == 1"
    >
      <div
        @click="showListItem()"
        v-if="current_categories.items.length > 1"
        class="btn-menu small"
        style="position: relative"
      >
        <img
          v-if="showMenu"
          src="/themes/web/assets/images/icon/icon-menu.svg"
          alt=""
        />
        <img v-else src="/themes/web/assets/images/icon/icon-x.svg" alt="" />
        <div v-if="!showMenu" class="tools">
          <div class="" v-for="item in catagory.items" :key="item.id">
            <div
              :class="item.lock == 1 ? 'locked' : ''"
              @click="item.lock == 0 ? openItem(item) : ''"
              style="display: flex; gap: 10px; margin-bottom: 10px"
            >
              <img
                v-if="item.id == learning_item.id"
                src="/themes/web/assets/images/icon/arrow-left-orange.svg"
                alt=""
              />
              <img
                v-else-if="item.status == 1"
                src="/themes/web/assets/images/icon/checked-item.svg"
                alt=""
              />
              <img
                v-else-if="item.status == 2 && item.is_structured"
                style="margin-top: 3px"
                src="/themes/web/assets/images/icon/item-new.svg"
                alt=""
              />

              <p :class="item.id == learning_item.id ? 'active' : ''">
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="current_categories.items.length > 1">
        <button
          :disabled="isButtonDisabled"
          @click="learning_item.index != 0 ? pre(learning_item) : ''"
          style="margin-right: 8px"
          :class="
            learning_item.index != 0
              ? 'igs-btn small'
              : 'igs-btn small inactive'
          "
        >
          <img src="/themes/web/assets/images/icon/arrow-left.svg" alt="" />
        </button>
        <button
          :disabled="isButtonDisabled"
          @click="
            current_categories.items &&
            learning_item.index + 1 != current_categories.items.length &&
            current_categories.items[learning_item.index + 1].lock == 0
              ? next(learning_item)
              : ''
          "
          :class="
            current_categories.items &&
            learning_item.index + 1 != current_categories.items.length &&
            current_categories.items[learning_item.index + 1].lock == 0
              ? 'igs-btn small'
              : 'igs-btn small inactive'
          "
        >
          <img src="/themes/web/assets/images/icon/arrow-right.svg" alt="" />
        </button>
      </div>
      <!-- <img class="icon-arow" src="/themes/web/assets/images/icon/icon-arrow-right.svg" alt="">
            <p class="path">{{ lesson_name }}</p> -->
      <img
        v-if="current_categories.items.length > 1"
        class="icon-arow"
        src="/themes/web/assets/images/icon/icon-arrow-right.svg"
        alt=""
      />
      <p class="path">{{ current_categories.name }}</p>
      <img
        class="icon-arow"
        src="/themes/web/assets/images/icon/icon-arrow-right.svg"
        alt=""
      />
      <p class="path-1">{{ learning_item.name }}</p>

      <router-link
        class="btn-close"
        :to="{
          path:
            '/lesson-package/' +
            class_id +
            '/' +
            course_id +
            '-' +
            module_id +
            '-' +
            lesson_id,
        }"
      >
        <img src="/themes/web/assets/images/icon/icon-x.svg" alt="" />
        <p>Close</p>
      </router-link>
    </div>
    <div
      :class="
        current_categories && current_categories.package_type == 1
          ? 'webview'
          : 'webview test'
      "
    >
      <iframe
        allow="microphone *;clipboard-read *; clipboard-write *"
        :src="learning_item ? learning_item.content : ''"
        frameborder="0"
        allowfullscreen
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  getListItem,
  saveCurrentItem,
  updateItem,
  submitItem,
  saveDarfAsm,
  updateStatus,
} from "../../models/dashboard";
import listItem from "../modal/Learning/listItem.vue";
import { fetchWithHeaders } from "../../APIGateWay";

export default {
  name: "LessonDetail",
  components: { listItem },
  title: "LessonDetail",

  data() {
    return {
      isButtonDisabled: false,
      current_categories: {},
      list_catagory: [],
      course_id: this.$route.params.course_id,
      module_id: this.$route.params.module_id,
      item_id: this.$route.params.item_id,
      lesson_id: this.$route.params.lesson_id,
      lesson_package_id: this.$route.params.lesson_package_id,
      class_id: this.$route.params.class_id,
      learning_item: {},
      lesson_name: "",
      showMenu: true,
      catagory: {},
      startTime: null,
      elapsedTime: 0,
      intervalId: null,
      action_type: "login",
      type1: ['reading_text_tooltip', 'text_image_video'],
      type2: ['matching1', 'matching2', 'matching3', 'pronunciation_record', 'open_ended', 'reading_text_hybrid', 'mcq', 'gap2', 'carousel'],
      type3: ['end_of_lesson', 'assignment', 'practice']
    };
  },

  created() {
    window.addEventListener("message", this.handleMessage);
    this.getListItem();
  },

  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage);
    this.stopTimer();
    this.coutTimeSpent(null, "end");
  },

  computed: {},

  mounted() {
    if (this.$store.getters.isShowMenu) {
      $(".webview").css({ left: "130px" });
    } else {
      $(".webview").css({ left: "50px" });
    }
  },

  methods: {
    async coutTimeSpent(event, type) {
      if (
        (this.type1.includes(this.learning_item.type_code)) 
        || (type == 'login' && this.type2.includes(this.learning_item.type_code))
        || (type == 'end' && this.type3.includes(this.learning_item.type_code))
      ) {
        const params = {
          item_id: this.learning_item.id,
          item_type: this.learning_item.type_code,
          course_id: this.course_id,
          module_id: this.module_id,
          lesson_id: this.lesson_id,
          lesson_package_id: this.lesson_package_id,
          class_id: this.class_id,
          is_flex: this.learning_item.is_flex,
          time: this.type3.includes(this.learning_item.type_code) ? 0 : this.elapsedTime,
          action_type: type,
          type: "learning",
        };

        try {
          const data = await fetchWithHeaders("/courses/counttime", params, {
            method: "POST",
          });
          console.log(data);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async coutTimeSpent1(event, action_type, type) {
      if (
        this.type1.includes(this.learning_item.type_code)
      ) {
        return
      }

      if (action_type == "login") {
        this.elapsedTime = 0;
        this.stopTimer();
        this.startTimer();
      }

      const params = {
        item_id: this.learning_item.id,
        item_type: this.learning_item.type_code,
        course_id: this.course_id,
        module_id: this.module_id,
        lesson_id: this.lesson_id,
        lesson_package_id: this.lesson_package_id,
        class_id: this.class_id,
        is_flex: this.learning_item.is_flex,
        time: this.elapsedTime,
        action_type: action_type,
        type: type,
      };

      try {
        const data = await fetchWithHeaders("/courses/counttime", params, {
          method: "POST",
        });
        this.elapsedTime = 0;
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    },
    startTimer() {
      this.startTime = Date.now();
      this.intervalId = setInterval(() => {
        this.elapsedTime = Math.floor((Date.now() - this.startTime) / 1000);
      }, 1000);
    },
    stopTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },

    async showListItem() {
      this.showMenu = !this.showMenu;
      if (!this.showMenu) {
        $(".btn-menu").css({ "border-radius": "8px 8px 0 0" });
      } else {
        $(".btn-menu").css({ "border-radius": "8px" });
      }
      // const cfm = await this.$refs.listItem.show({
      //     lang: this.lang,
      //     list_catagory: this.list_catagory,
      //     learning_item: this.learning_item,
      // });
      // if (!cfm) {
      //     return false;
      // }
    },

    async openItem(item) {
      const currentRoute = this.$route.fullPath;
      const targetRoute =
        "/lesson/" +
        this.class_id +
        "/" +
        this.course_id +
        "-" +
        this.module_id +
        "-" +
        this.lesson_id +
        "-" +
        this.lesson_package_id +
        "-" +
        item.id;

      if (targetRoute !== currentRoute) {
        this.$router.push({ path: targetRoute });
        this.learning_item = item;
        this.getItemInfo();
      }
    },

    async next() {
      this.isButtonDisabled = true;
      await this.coutTimeSpent(null, "end");
      this.openItem(
        this.current_categories.items[this.learning_item.index + 1]
      );
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 1000);
    },
    async pre() {
      this.isButtonDisabled = true;
      await this.coutTimeSpent(null, "end");
      this.openItem(
        this.current_categories.items[this.learning_item.index - 1]
      );
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 1000);
    },

    getItemInfo() {
      console.log("get_item_info");
      this.elapsedTime = 0;
      this.stopTimer();
      this.startTimer();
      this.coutTimeSpent(null, "login");

      // axios.all([
      //     updateItem({
      //         item_id: this.learning_item.id,
      //         course_id: this.course_id,
      //         item_type: this.learning_item.type_code,
      //         module_id: this.module_id,
      //         lesson_id: this.lesson_id,
      //         lesson_package_id: this.lesson_package_id,
      //         class_id: this.class_id,
      //         action_type: "login",
      //     }),
      // ]).then(axios.spread((response) => {
      //     if(response.data.code != 0){
      //         return;
      //     }
      // })).catch(error => {
      //     console.log('sai');
      // });
    },

    async handleMessage(event) {
      if (event.data.type === "item_inprogress") {
        this.updateStatus();
      }
      if (event.data.type === "update_item_info") {
        await this.coutTimeSpent1(null, "end", "submit");
        await this.submitItem();
        console.log("submit");
      }
      if (event.data.type === "start") {
        await this.coutTimeSpent1(null, "login", "learning");
        console.log("start");
      }
      // if (event.data.type === "submit") {
      //   await this.coutTimeSpent1(null, "end", "submit");
      //   console.log("start");
      // }
      if (event.data.type === "save_draf") {
        await this.coutTimeSpent1(null, "save", "save");
        console.log("save");
      }
      if (event.data.type == "close_iframe") {
        // const targetRoute = '/lesson-package/' + this.class_id +'/'+this.course_id+'-'+this.module_id+'-'+this.lesson_id;
        // this.$router.push({path: targetRoute});

        const previousRoute = this.$store.state.previousRoute;
        if (previousRoute && previousRoute.path !== this.$route.path) {
          this.$router.push(previousRoute.path);
        }
      }
      if (event.data.type == "save_draf") {
        console.log("savedraf");
        this.saveDarfAsm();
      }
    },
    saveDarfAsm() {
      const params = {
        item_id: this.learning_item.id,
        item_type: this.learning_item.type_code,
        course_id: this.course_id,
        module_id: this.module_id,
        lesson_id: this.lesson_id,
        lesson_package_id: this.lesson_package_id,
        class_id: this.class_id,
      };
      axios.all([saveDarfAsm(params)]).then(axios.spread((response) => {}));
    },
    getListItem(status) {
      const params = {
        course_id: this.course_id,
        module_id: this.module_id,
        lesson_id: this.lesson_id,
        lesson_package_id: this.lesson_package_id,
        class_id: this.class_id,
        timezone: localStorage.getItem("timezone"),
      };
      const that = this;
      that.isLoading = true;
      axios.all([getListItem(params)]).then(
        axios.spread((response) => {
          that.isLoading = false;
          if (response.data.code != 0) {
            return;
          }

          that.lesson_name = response.data.results.lesson_name;
          that.list_catagory = response.data.results.categories;

          that.catagory = response.data.results.categories[0];

          if (that.item_id == 0) {
            if (
              response.data.results.current_categories &&
              response.data.results.current_categories.id != 0
            ) {
              const filteredArray = that.list_catagory.filter(
                (item) => item.id == response.data.results.current_categories.id
              );
              const filteredItems = filteredArray[0].items;
              if (status != "submit") {
                that.learning_item = filteredItems.find(
                  (e) => e.is_learning === 1
                );
              }
              that.current_categories = that.list_catagory.find(
                (item) => item.id == response.data.results.current_categories.id
              );
            } else {
              if (status != "submit") {
                that.learning_item = that.list_catagory[0].items[0];
              }
              that.current_categories = that.list_catagory[0];
            }
          } else {
            const itemsIdToFind = that.item_id;
            const foundListItems = that.list_catagory.filter((category) => {
              return category.items.some((item) => item.id == itemsIdToFind);
            });
            that.current_categories = foundListItems[0];
            if (status != "submit") {
              if (foundListItems.length > 0) {
                foundListItems.forEach((listItem) => {
                  const itemsWithId = listItem.items.filter(
                    (item) => item.id == that.item_id
                  );
                  if (itemsWithId.length > 0) {
                    that.learning_item = itemsWithId[0];
                  }
                });
              }
            }
          }
          if (status != "submit") {
            this.getItemInfo();
          }
        })
      );
    },

    updateStatus() {
      console.log(5555555);
      const params = {
        item_id: this.learning_item.id,
        course_id: this.course_id,
        module_id: this.module_id,
        lesson_id: this.lesson_id,
        lesson_package_id: this.lesson_package_id,
        class_id: this.class_id,
        status: "inprogress",
      };
      axios.all([updateStatus(params)]).then(axios.spread((response) => {}));
    },

    submitItem() {
      const params = {
        item_id: this.learning_item.id,
        item_type: this.learning_item.type_code,
        course_id: this.course_id,
        module_id: this.module_id,
        lesson_id: this.lesson_id,
        lesson_package_id: this.lesson_package_id,
        class_id: this.class_id,
        is_flex: this.learning_item.is_flex,
        action_type: "login",
        type: "learning",
      };
      console.log("params: ", params);
      axios.all([submitItem(params)]).then(
        axios.spread((response) => {
          // if (response.data.code != 0) {
          //     return;
          // }
          this.getListItem("submit");
        })
      );
    },

    saveCurrentItem(itemId) {
      formData.append("item_id", itemId);
      saveCurrentItem(formData)
        .then((response) => {
          that.$store.commit("setLoading", false);
          if (response.data.code != 0) {
            return;
          }
        })
        .catch((response) => {
          that.$store.commit("setLoading", false);
        });
    },
  },
};
</script>

<style scoped>
.tools {
  position: absolute;
  z-index: 99;
  bottom: 0;
  border-radius: 0px 16px 16px 16px;
  background: var(--Creme-Dark, #f8fafc);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px 48px 18px 24px;
  left: 0;
  top: 36px;
  height: fit-content;
  height: 85vh;
  overflow: auto;
}
.tools img {
  position: absolute;
}
.tools p {
  color: var(--Liquorice-Light, #777);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 20.8px */
  letter-spacing: 0.32px;
  margin-left: 30px;
  text-wrap: nowrap;
}
.tools p.active {
  color: var(--Pumpkin-Light, #fc7a1b);
}
#lesson {
  margin-right: 24px;
  overflow: auto;
  height: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.header {
  height: 32px;
  margin-bottom: 16px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
}
.webview {
  box-shadow: 4px 0px 24px rgba(0, 0, 0, 0.25);
  background: #f8fafc;
  border-radius: 24px;
  /* height: 738px; */
  height: calc(100% - 100px);
  overflow: hidden;
  position: fixed;
  right: 25px;
}
.webview.test {
  height: calc(100% - 50px) !important;
  margin-top: 25px !important;

  /* left: 130px; */
}
.locked > p {
  color: var(--Cloud-Light, #ddd);
}
.igs-btn.inactive {
  background: #777;
  cursor: not-allowed;
}
.igs-btn {
  height: 32px;
  width: 47px;
  background: #ffffff;
}
.btn-menu {
  height: 36px;
  width: 47px;
  background: #ffffff;
  background: var(--Creme-Dark, #f8fafc);
  width: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}
.path {
  letter-spacing: 0.02em;
  color: #00afff;
  font-weight: 500;
  font-size: 16px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.path-1 {
  letter-spacing: 0.02em;
  font-weight: 700;
  font-size: 16px;
  color: #f8fe5f;
  flex: 1;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-close {
  display: flex;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;

  width: 114px;
  height: 36px;

  background: #fff;

  padding: 8px 24px;
  border-radius: 48px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
}
.btn-close img {
  width: 15px;
}
.btn-close p {
  text-align: center;
  color: #1d1d1f;
}
</style>
