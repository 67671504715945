<template>
    <div id="signin">
 
        <div class="right">
            <!-- <div class="bottom">
                <span>Don’t have an account?</span><span>Create new account</span>
            </div> -->

            <!-- <img class="ivy" style="position: absolute; right: 30px; top: 30px;" src="https://sis-sso-test.edunext.edu.vn/auth/resources/gt52a/login/equest/img/logo-login.svg" alt=""> -->



            <div class="right-content">
                <div style="display: flex; align-items: center; justify-content: center;">
                    <img class="ivy" src="/themes/web/assets/images/icon/logo/logo-login.svg" alt="">
                </div>

                <h3 style="text-align: center;">Welcome to LMS</h3>
                <div class="error-message">{{ $t(`${error.message}`) }}</div>
                <form id="create_form" method="POST" encType="multipart/form-data" @submit.prevent="onSubmit">
                    <div class="form-group">
                        <!-- <div class="label">Username or Email Address</div> -->
                        <div class="label">Username</div>
                        <div :class="focusUsername || (error.message != '') ? 'form focus' : 'form'">
                            <img class="icon-search" src="/themes/web/assets/images/icon/icon-mail.svg" alt="">
                            <input type="text" @click="focusUsername = true" @blur="focusUsername = false" name="username"
                                placeholder="Username" v-model="user.username">
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- <div class="parent-label">
                            <div class="label">Password</div>
                            <div class="p-forgor-password">Forgot Password?</div>
                        </div> -->
                        <div style="display: flex; justify-content: space-between">
                            <div class="label">Password</div>
                            <div class="label"> 
                                <a style="color: #FC7A1B; font-weight: 600; text-decoration: none;" :href="getForgotPasswordUri()">Forgot Password?</a>
                            </div>
                        </div>
                        <div :class="focusPassword || (error.message != '') ? 'form focus' : 'form'">
                            <img class="icon-search" src="/themes/web/assets/images/icon/icon-lock.svg" alt="">
                            <input @click="focusPassword = true" @blur="focusPassword = false"
                                :type="showPass ? 'text' : 'password'" name="password" placeholder="Password"
                                v-model="user.password">
                            <img v-if="user.password == ''" @click="showPass = !showPass" class="icon-eye"
                                src="/themes/web/assets/images/icon/icon-eye.svg" alt="">
                            <img v-else @click="showPass = !showPass" class="icon-eye"
                                :src="!showPass ? '/themes/web/assets/images/icon/icon-eye-active.svg' : '/themes/web/assets/images/icon/eye-hide.png'" alt="">
                        </div>
                    </div>
                    <button class="btn btn-login">
                        Sign in
                    </button>
                    <!-- <div class="btn btn-free">
                        Start free trial, no account needed
                    </div> -->

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { authLogin } from '../../models/auth'
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: "Signin",
    components: {
        // Carousel, Slide
    },
    data() {
        return {
            activeIndex: 0,
            test: [1, 2, 3, 4],
            showPass: false,
            focusUsername: false,
            focusPassword: false,
            error: {
                message: '',
                field_username: '',
                field_password: ''
            },
            user: {
                username: '',
                password: ''
            },
        };
    },
    methods: {
        getForgotPasswordUri(){
            // ?ReturnUrl=/connect/authorize/callback?client_id=login&redirect_uri=http://localhost:8080/verifications&response_type=code&scope=openid profile email offline_access&state=9f6a62939fb74ea9a81d44908a591cdd&code_challenge=oXWMLWCi9csyX-oMl4YbiDyLDDvlgO_x0ZzOaXNA9y4&code_challenge_method=S256&response_mode=query

            let obj = {
                returnUrl: "/connect/authorize/callback",
                client_id: process.env.VUE_APP_CLIENT_ID,
                redirect_uri: process.env.VUE_APP_SIS_REDIRECT_URI,
                response_type: process.env.VUE_APP_RESPONSE_TYPE,
                scope: process.env.VUE_APP_SCOPE,
            }
            let queryString =  `client_id=${obj.client_id}&redirect_uri=${obj.redirect_uri}&response_type=${obj.response_type}&scope=${obj.scope}`

            return `${process.env.VUE_APP_SIS_AUTH}/Account/ForgotPassword?ReturnUrl=${obj.returnUrl}?${encodeURIComponent(queryString)}&%26state%3D9f6a62939fb74ea9a81d44908a591cdd%26code_challenge%3DoXWMLWCi9csyX-oMl4YbiDyLDDvlgO_x0ZzOaXNA9y4%26code_challenge_method%3DS256%26response_mode%3Dquery`
        },
        pageChange(i) {
            this.activeIndex = i
        },

        indexString(index) {
            return index < 10 ? '0' + index : index
        },

        changeIndex(index) {
            this.activeIndex = index;
        },

        isActive(index) {
            return index === this.activeIndex;
        },

        resetErrorsValid() {
            this.error = {
                message: '',
            }
        },

        onSubmit(e) {
            this.resetErrorsValid();

            if (!this.user.username) {
                // this.error.message = this.$i18n.t('enter_username')
                this.error.message = "Oops! That username / password combination is not valid"
            }

            if (!this.user.password) {
                // this.error.message = this.$i18n.t('enter_password')
                this.error.message = "Oops! That username / password combination is not valid"
            }

            if (this.error.message != '') {
                this.focusPassword = true
                this.focusUsername = true
                this.error.message = "Oops! username / password cannot be empty"
                return false;
            }

            const username = this.user.username;
            const password = this.user.password;
            const role_type = 0;

            this.login({ username, password, role_type });

            e.preventDefault();
        },

        login(user) {
            const that = this;

            that.$store.commit("setLoading", true);
            authLogin(user).then((response) => {
                that.$store.commit("setLoading", false);
                if (response.data.code != 0) {
                    console.log(response.data);
                    // this.error.message = response.data.error_code;
                    if(response.data.code == 2){
                        this.error.message = "You do not have access to the system. Please contact the administrator."
                    }else{
                        this.error.message = "Oops! That username / password combination is not valid"
                    }

                    return false;
                }

                that.$store.commit('setAuth', true);
                that.$store.commit("SET_ROLE_TYPE", 'student');
                that.$store.commit("SET_CHECK_POPUP", false)
                this.$store.dispatch("SET_USER_INFO");
                localStorage.removeItem(`warning_timezone_${this.user.username}`);
                localStorage.setItem('access_token', response.data.results.access_token);

                // this.$router.push({
                //     name: 'home'
                // });
                window.location.href = "/home";
            }).catch(({ response }) => {
                this.$store.commit('setAuth', false);
                that.$store.commit("setLoading", false);

                that.error.message = 'An error occurred, please try again later.';
            });
        }
    }
}
</script>

<style scoped>
#signin {
    display: flex;
    background: #FFFFFF;
    height: 100%;
    background-image: url(/public/images/main-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

#signin .right {
    padding: 48px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    /* width: 70%; */
    /* height: 100%; */
    width: 100%;
    /* margin-top: 10%; */
    align-items: center;
}

.pagination-line {
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 30px;
    display: flex;
    height: 100%;
    align-items: end;
}

.active {
    background-color: var(--Jelly-Light, #A560EB);
    flex: 1;
    box-sizing: border-box;
    border-radius: 5px;
}

.inactive {
    flex: 1;
    border-radius: 5px;
    background-color: var(--Creme-Light, #FFF);
    box-sizing: border-box;
}

.pagination {
    display: flex;
    height: 4px;
    background-color: var(--Creme-Light, #FFF);
    width: 100%;
    border-radius: 16px;
    margin: 16px;
}

#signin .left {
    width: 30%;
    height: 100vh;
    /* padding: 24px; */
    background-image: linear-gradient(to bottom, #381770, #7F2C86);
    /* width: 30%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100%; */
    overflow-y: auto;
}



.btn-more {
    border: 1px solid #CFCFCF;
    box-shadow: 4px 4px 0px #F2F3F4;
    border-radius: 48px;
    background: #FFFFFF;
    height: 52px;
    padding: 0 24px;
    font-weight: 700;
    font-size: 16px;
    color: #191715;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
}

.ivy {
    width: 200px;
    position: relative;
    margin-bottom: 40px;
}

.slider {
    width: 100%;
    align-self: center;
}

.left .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    margin-top: 26px;
    gap: 24px;
}

.bottom .info {}

.info p:nth-child(1) {
    color: #6E6B7B;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}

.info p:nth-child(2) {
    letter-spacing: 0.02em;
    color: #000000;
    font-weight: 700;
    font-size: 24px;
}

.info p:nth-child(3) {
    color: #6E6B7B;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.02em;
}

.right-content {
    width: 480px;
    /* margin-top: 10%; */
    background: #FFFFFF;
    border-radius: 24px 24px 24px 24px;
    height: 523px;
    padding: 48px;
}

.form-group {
    background: #FFFFFF;
    margin-bottom: 16px;
}

.form-group .form {
    height: 56px;
    width: 384px;
    border-radius: 56px;
    border: 1px solid #EAEAEA;
    background: #F8FAFC;
    display: flex;
    align-items: center;
    padding-right: 20px;
    padding-left: 10px;

}

.form-group .form.focus {
    border-radius: 56px;
    border: 1px solid var(--pumpkin-light, #FC7A1B);
    background: var(--creme-dark, #F8FAFC);
}

.form-group .form input {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    background: #F8FAFC;
}

.form-group .label {
    color: #6E6B7B;
    font-size: 16px;
    letter-spacing: 0.02em;
    margin-bottom: 8px;
}

.parent-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p-forgor-password {
    color: #F97316;
    letter-spacing: 0.02em;
    font-size: 16px;
    cursor: pointer;
}

.right h3 {
    letter-spacing: 0.02em;
    color: #000000;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10px;
}

.btn {
    width: 100%;
    height: 52px;
    border: 1px solid #CFCFCF;
    box-shadow: 4px 4px 0px #F2F3F4;
    border-radius: 48px;
    color: #191715;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}

.btn-login {
    background: #F8FE5F;
    margin-bottom: 24px;
}

.btn-free {
    margin-bottom: 16px;
}

.right .bottom {
    display: flex;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 25px;
    right: 24px;
}

.right .bottom span:nth-child(1) {
    color: #403D3D;
    letter-spacing: 0.02em;
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
}

.right .bottom span:nth-child(2) {
    color: #A560EB;
    letter-spacing: 0.02em;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    text-decoration-line: underline;
    white-space: nowrap;
}

.icon-eye {
    cursor: pointer;
    width: 20px;
}

.error-message {
    color: #FF3D54;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    padding-bottom: 24px;
    /* white-space: nowrap; */
    text-align: center;
}
</style>
